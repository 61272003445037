<template>
    <div class="sovt__charts" :class="{ hidden: !isSynodicOrbitChartAcive, inverted: isThemeInverted }">
        <p class="sovt__title">{{ $t.charts }}</p>
        <p class="sovt__charts-info" v-show="!selectedCalculatedObject">
            {{ $t.invisibleCharts }}
        </p>
        <SynodicOrbitChartsSwitcher v-show="selectedCalculatedObject" />
        <div class="sovt__charts-container" v-show="selectedCalculatedObject">
            <div class="sovt__chart sovt__chart--earth-distance">
                <canvas id="earthDistanceChart"></canvas>
            </div>
            <div class="sovt__chart sovt__chart--phase-angle">
                <canvas id="phaseAngleChart"></canvas>
            </div>
            <div class="sovt__chart sovt__chart--magnitude">
                <canvas id="magnitudeChart"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import * as ChartJs from "chart.js";
import moment from "moment";
import "chartjs-adapter-moment";
import annotationPlugin from "chartjs-plugin-annotation";
import SynodicOrbitChartsSwitcher from "@/components/sovt/SynodicOrbitChartsSwitcher";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import SynodicChartService from "@/services/synodic-chart.service";
import TimeFrameService from "@/services/timeframe.service";
import VisualisationService from "@/services/visualisation.service";

export default {
    name: "SynodicOrbitCharts",
    components: {
        SynodicOrbitChartsSwitcher,
    },
    data() {
        this.magnitudeChart = null;
        this.earthDistanceChart = null;
        this.phaseAngleChart = null;
        return {
            earthDistanceChartConfig: SynodicChartService.generateChartConfig({
                y: {
                    title: `${this.$t.earthDistance} (${this.$t.au})`,
                },
                x: {
                    title: `${this.$t.time
                        } (${this.$t.timeUnit_d.toLowerCase()})`,
                },
            }),
            phaseAngleChartConfig: SynodicChartService.generateChartConfig({
                y: {
                    title: `${this.$t.phaseAngle} (${this.$t.degrees})`,
                },
                x: {
                    title: `${this.$t.time
                        } (${this.$t.timeUnit_d.toLowerCase()})`,
                },
            }),
            magnitudeChartConfig: SynodicChartService.generateChartConfig({
                y: {
                    title: `${this.$t.visualMagnitude}`,
                },
                x: {
                    title: `${this.$t.time
                        } (${this.$t.timeUnit_d.toLowerCase()})`,
                },
            }),
            chartLineDateValue: null,
        };
    },
    computed: {
        settings() {
            return VisualisationService.getSettings();
        },

        isSynodicOrbitChartAcive() {
            return this.settings && this.settings.synodicOrbitCharts;
        },

        selectedCalculatedObject() {
            return SynodicCalculationService.getSelectedCalculatedObject();
        },

        perturbedSynodicOrbitList() {
            return SynodicCalculationService.getPerturbedSynodicOrbitList();
        },

        isSynodicChartsDaysMode() {
            return SynodicChartService.getSynodicOrbitChartsSettings()
                .daysNumberMode;
        },

        selectedTimeframe() {
            return TimeFrameService.getTimeFrame().converted;
        },

        simulationTime() {
            return TimeFrameService.getSimulationTime();
        },

        getChartsDataInfo() {
            return SynodicChartService.getSynodicOrbitChartsData().objects;
        },

        timeAxisTitle() {
            return `${this.$t.time} (${this.isSynodicChartsDaysMode
                    ? this.$t.timeUnit_d.toLowerCase()
                    : this.$t.timeUnit_date.toLowerCase()
                })`;
        },

        timeAxisValues() {
            const { date } = SynodicChartService.getSynodicOrbitChartsData();
            return date.map((date, index) =>
                this.isSynodicChartsDaysMode ? index : date
            );
        },

        timeAxisType() {
            return this.isSynodicChartsDaysMode ? "linear" : "time";
        },

        isThemeInverted() {
            return VisualisationService.getInvertColors();
        },
    },
    methods: {
        updateCharts(selectedObject) {
            const chartsMap = this.getChartsData(selectedObject);
            if (!chartsMap) {
                return;
            }
            const dataNumber = chartsMap.magnitude.value.length;
            const currentDateValue = this.getCurrentDateChartValue();
            const {
                step: timeAxisStep,
            } = SynodicChartService.setUpChartAxisValues(
                [0, dataNumber],
                false,
                dataNumber === 1
            );
            Object.keys(chartsMap).forEach((chartInfo) => {
                const { label, value } = chartsMap[chartInfo];
                const [chart] = this.getChart(label);
                const {
                    step: valueStep,
                    startValue,
                } = SynodicChartService.setUpChartAxisValues(
                    value,
                    true,
                    value.length === 1
                );
                this.updateChartTimeAxisValues(chart, timeAxisStep);
                chart.options.scales.y.ticks.stepSize = valueStep;
                const dataPoints = value.map((v, index) => ({
                    x: this.timeAxisValues[index],
                    y: v,
                }));
                this.updateChartDateLine(chart, dataPoints, currentDateValue);
                chart.data.datasets[0] = { data: dataPoints, ...(chartInfo === 'magnitude' && {originalData: chartsMap[chartInfo].originalValue}) };
                chart.options.scales.y.beginAtZero = !(startValue !== 0);
                chart.options.scales.y.min = startValue;
                if (chartInfo === 'magnitude') {
                    const magnitudeMaxValue = Math.max(...chartsMap[chartInfo].originalValue);
                    chart.options.scales.y.max = magnitudeMaxValue > 32 ? 32 : null;
                }
                chart.update();
            });
        },

        updateChartsTimeAxis() {
            const chartsMap = this.getChartsData(this.selectedCalculatedObject);
            const dataNumber = chartsMap.magnitude.value.length;
            const currentDateValue = this.getCurrentDateChartValue();
            const {
                step: timeAxisStep,
            } = SynodicChartService.setUpChartAxisValues(
                [0, dataNumber],
                false,
                dataNumber === 1
            );
            [
                this.magnitudeChart,
                this.earthDistanceChart,
                this.phaseAngleChart,
            ].forEach((chart, idx) => {
                const isMagnitudeChart = idx === 0;
                this.updateChartTimeAxisValues(chart, timeAxisStep);
                const dataPoints = chart.data.datasets[0].data.map(
                    ({ y }, index) => ({ x: this.timeAxisValues[index], y })
                );
                this.updateChartDateLine(chart, dataPoints, currentDateValue);
                chart.data.datasets[0] = { data: dataPoints, ...(isMagnitudeChart && {originalData: chartsMap.magnitude.originalValue}) };
                chart.update();
            });
        },

        updateCurrentDate() {
            const currentDateValue = this.getCurrentDateChartValue();
            if (currentDateValue === this.chartLineDateValue) {
                return;
            }
            [
                this.magnitudeChart,
                this.earthDistanceChart,
                this.phaseAngleChart,
            ].forEach((chart) => {
                this.updateChartDateLine(
                    chart,
                    chart.data.datasets[0].data,
                    currentDateValue
                );
                chart.update();
            });
            this.chartLineDateValue = currentDateValue;
        },

        updateChartDateLine(chart, values, date) {
            const valueForDate = SynodicChartService.getValueForCurrentDate(
                values,
                date
            );
            if (!valueForDate) {
                this.toggleDateAnnotationVisibility(chart, false);
                return;
            }
            this.toggleDateAnnotationVisibility(chart);
            chart.options.plugins.annotation.annotations.lineAnnotation.value = date;
            chart.options.plugins.annotation.annotations.pointAnnotation.xValue = date;
            chart.options.plugins.annotation.annotations.pointAnnotation.yValue = valueForDate;
        },

        updateChartTimeAxisValues(chart, timeAxisStep) {
            chart.options.scales.x.title.text = this.timeAxisTitle;
            chart.options.scales.x.type = this.timeAxisType;
            chart.options.scales.x.min = this.timeAxisValues[0];
            chart.options.scales.x.time = this.isSynodicChartsDaysMode
                ? {}
                : {
                    ...SynodicChartService.TIME_DISPLAY_SETTINGS,
                    stepSize: timeAxisStep,
                };
            chart.options.scales.x.ticks = {
                ...chart.options.scales.x.ticks,
                stepSize: this.isSynodicChartsDaysMode ? timeAxisStep : null,
                ...(this.isSynodicChartsDaysMode
                    ? SynodicChartService.DAY_TICKS_SETTINGS
                    : SynodicChartService.DATE_TICKS_SETTINGS),
            };
        },

        toggleDateAnnotationVisibility(chart, isVisible = true) {
            if (
                isVisible ===
                chart.options.plugins.annotation.annotations.lineAnnotation
                    .display
            ) {
                return;
            }
            chart.options.plugins.annotation.annotations.lineAnnotation.display = isVisible;
            chart.options.plugins.annotation.annotations.pointAnnotation.display = isVisible;
        },

        getCurrentDateChartValue() {
            const currentDateString = moment
                .utc(TimeFrameService.getSimulationTime())
                .format("YYYY-MM-DD");
            const currentTimestamp = moment(
                currentDateString,
                "YYYYMMDD"
            ).valueOf();
            const { date } = SynodicChartService.getSynodicOrbitChartsData();
            return this.isSynodicChartsDaysMode
                ? date.indexOf(currentTimestamp)
                : currentTimestamp;
        },

        getChartsData(selectedObject) {
            const selectedObjectDesignator = this.getSelectedObjectDesignator(
                selectedObject
            );
            const { objects } = SynodicChartService.getSynodicOrbitChartsData();
            if (
                !selectedObjectDesignator ||
                !objects[selectedObjectDesignator]
            ) {
                return null;
            }
            const { apparentMag, earthDistance, phaseAngle, apparentMagOriginalValues } = objects[
                selectedObjectDesignator
            ];
            return {
                magnitude: {
                    label: "magnitudeChart",
                    value: apparentMag,
                    originalValue: apparentMagOriginalValues,
                },
                earthDistance: {
                    label: "earthDistanceChart",
                    value: earthDistance,
                },
                phaseAngle: {
                    label: "phaseAngleChart",
                    value: phaseAngle,
                },
            };
        },

        getChart(chartName = null) {
            const charts = {
                magnitudeChart: this.magnitudeChart,
                earthDistanceChart: this.earthDistanceChart,
                phaseAngleChart: this.phaseAngleChart,
            };
            return !chartName ? Object.values(charts) : [charts[chartName]];
        },

        getSelectedObjectDesignator(selectedObject) {
            if (!selectedObject) {
                return null;
            }
            const isPerturbedOrbit = SynodicCalculationService.getPerturbedSynodicOrbitList().includes(
                selectedObject.designator
            );
            return isPerturbedOrbit
                ? `${selectedObject.designator}_perturbed`
                : selectedObject.designator;
        },
    },
    watch: {
        selectedCalculatedObject(selectedObject) {
            if (!selectedObject) {
                return;
            }
            const selectedObjectDesignator = this.getSelectedObjectDesignator(
                selectedObject
            );
            if (
                !selectedObjectDesignator ||
                !this.getChartsDataInfo[selectedObjectDesignator]
            ) {
                return;
            }
            this.updateCharts(selectedObject);
        },

        getChartsDataInfo: {
            deep: true,
            handler(chartData) {
                if (!chartData || !this.selectedCalculatedObject) {
                    return;
                }
                const selectedObjectDesignator = this.getSelectedObjectDesignator(
                    this.selectedCalculatedObject
                );
                if (
                    !selectedObjectDesignator ||
                    !chartData[selectedObjectDesignator]
                ) {
                    return;
                }
                this.updateCharts(this.selectedCalculatedObject);
            },
        },

        perturbedSynodicOrbitList() {
            if (!this.selectedCalculatedObject) {
                return;
            }
            this.updateCharts(this.selectedCalculatedObject);
        },

        isSynodicChartsDaysMode() {
            this.updateChartsTimeAxis();
        },

        simulationTime() {
            if (
                !this.selectedCalculatedObject ||
                !this.isSynodicOrbitChartAcive
            ) {
                return;
            }
            this.updateCurrentDate();
        },

        isSynodicOrbitChartAcive(isActive) {
            if (!this.selectedCalculatedObject || !isActive) {
                return;
            }
            this.updateCurrentDate();
        },
    },
    mounted() {
        ChartJs.Chart.register(annotationPlugin);
        ChartJs.Chart.register.apply(
            null,
            Object.values(ChartJs).filter((chartClass) => chartClass.id)
        );
        this.magnitudeChart = new ChartJs.Chart(
            document.querySelector("#magnitudeChart"),
            this.magnitudeChartConfig
        );
        this.earthDistanceChart = new ChartJs.Chart(
            document.querySelector("#earthDistanceChart"),
            this.earthDistanceChartConfig
        );
        this.phaseAngleChart = new ChartJs.Chart(
            document.querySelector("#phaseAngleChart"),
            this.phaseAngleChartConfig
        );
        this.selectedCalculatedObject &&
            this.updateCharts(this.selectedCalculatedObject);
    },
};
</script>

<style lang="scss">
@import "@/styles/sovt/charts.scss";
</style>
