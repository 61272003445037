import axios from "axios";
import ObservatoryService from "@/services/observatory.service";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import VisualisationService from "@/services/visualisation.service";
import UtilsService from "@/services/utils.service";
import store from "@/store";
import CommonActions from "@/store/common/common-actions";
import CommonGetters from "@/store/common/common-getters";
import Config from "@/constants/config";
import TimeFrameService from "./timeframe.service";
import SpacekitService from "./spacekit.service";

const SyntheticObjectsService = {
    SCD_CUSTOM_OBSERVATORY_ENABLED: false,
    SYNTHETIC_OBJECT_SUFFIX: "_synthetic-object",
    LD_TO_AU: 0.0025695686589742,
    LD_LIMIT: 3,

    isToolWithSyntheticObjects() {
        const tools = SyntheticObjectsService.getToolsWithSyntheticObjects();
        const currentTool = VisualisationService.getTool();
        return tools.length && tools.includes(currentTool);
    },

    isDefaultSyntheticObjects() {
        const currentTool = VisualisationService.getTool();
        return currentTool !== "fvt";
    },

    getToolsWithSyntheticObjects() {
        const config = SyntheticObjectsService.getSyntheticObjectsConfig();
        return config.availableTools;
    },

    getDefaultSyntheticInputsList() {
        const config = SyntheticObjectsService.getSyntheticObjectsConfig();
        return config.parametersInputs.default;
    },

    getFvtSyntheticInputsList() {
        const config = SyntheticObjectsService.getSyntheticObjectsConfig();
        return config.parametersInputs.fvt;
    },

    getSyntheticObjectsList() {
        const isDefaultSyntheticObjects =
            SyntheticObjectsService.isDefaultSyntheticObjects();
        return isDefaultSyntheticObjects
            ? SyntheticObjectsService.getSyntheticDefaultObjectsList()
            : SyntheticObjectsService.getSyntheticFvtObjectsList();
    },

    getSyntheticDefaultObjectsList() {
        return store.getters[CommonGetters.syntheticDefaultObjectsList];
    },

    getSyntheticFvtObjectsList() {
        return store.getters[CommonGetters.syntheticFvtObjectsList];
    },

    getSyntheticObjectName(syntheticObject) {
        return syntheticObject.objectName;
    },

    setSyntheticDefaultObjectsList(objectsList) {
        store.dispatch(
            CommonActions.setSyntheticDefaultObjectsList,
            objectsList
        );
    },

    setSyntheticFvtObjectsList(objectsList) {
        store.dispatch(CommonActions.setSyntheticFvtObjectsList, objectsList);
    },

    isSyntheticObject(objectDesignator) {
        const objectsList = SyntheticObjectsService.getSyntheticObjectsList();
        return (
            UtilsService.findItemIndexInObjectList(
                "designator",
                objectDesignator,
                objectsList
            ) !== -1
        );
    },

    getSyntheticObjectDataByDesignator(designator) {
        const objectsList = SyntheticObjectsService.getSyntheticObjectsList();
        return UtilsService.findItemInObjectList(
            "designator",
            designator,
            objectsList
        );
    },

    getObjectDesignator(realObject) {
        return (
            realObject.number ? realObject.number : realObject.name
        ).toString();
    },

    async getRealObjectParameters(designator) {
        if (!designator) {
            return;
        }
        const apiUrl = SynodicCalculationService.getEphemeridesUrl(designator);
        const loadingObjects =
            SynodicCalculationService.loadingObjectsEphemerides();
        loadingObjects.push(designator);
        const response = await axios(apiUrl);
        return response.data.ephemerides;
    },

    getUpdatedObjectParameters(objectParameters) {
        const parameters = {};
        const updatedObjectParamters =
            SyntheticObjectsService.setMissingParamters(objectParameters);
        const parametersMap = SyntheticObjectsService.getObjectParamtersMap();
        Object.keys(parametersMap).map(
            (key) =>
                (parameters[parametersMap[key]] = updatedObjectParamters[key])
        );
        return parameters;
    },

    setMissingParamters(objectParameters) {
        !objectParameters.g && (objectParameters.g = 0.25);
        if (
            !objectParameters.aphelion &&
            objectParameters.a &&
            objectParameters.e
        ) {
            objectParameters.aphelion =
                objectParameters.a * (1 + objectParameters.e);
        }
        return objectParameters;
    },

    isUserDefinedObjectCustomObservatoryEnabled() {
        if (SyntheticObjectsService.SCD_CUSTOM_OBSERVATORY_ENABLED) {
            return true;
        }
        const isCustomObservatory =
            ObservatoryService.getSelectedObservatory().code === null;
        const isUserDefinedObject =
            !!SyntheticObjectsService.getSyntheticDefaultObjectsList().length;
        return !(isCustomObservatory && isUserDefinedObject);
    },

    createSyntheticObjectDesignator(objectName) {
        return `${objectName.replace(/[^\w\s-]/g, "").replace(/[-\s]+/g, "_")}${
            SyntheticObjectsService.SYNTHETIC_OBJECT_SUFFIX
        }`;
    },

    getSyntheticObjectParameters(designator) {
        const syntheticObjectData =
            SyntheticObjectsService.getSyntheticObjectDataByDesignator(
                designator
            );
        if (!syntheticObjectData) {
            return;
        }
        const syntheticParameters = {};
        const parametersMap = SyntheticObjectsService.getObjectParamtersMap();
        const parametersNames = Object.values(parametersMap);
        const parametersKeys = Object.keys(parametersMap);
        Object.keys(syntheticObjectData).forEach((parameterName) => {
            const parameterIndex = parametersNames.indexOf(parameterName);
            if (parameterIndex === -1) {
                syntheticParameters[parameterName] =
                    syntheticObjectData[parameterName];
                return;
            }
            syntheticParameters[parametersKeys[parameterIndex]] =
                syntheticObjectData[parameterName];
        });
        syntheticParameters["a"] =
            (syntheticParameters["q"] + syntheticParameters["aphelion"]) / 2;
        syntheticParameters["e"] =
            syntheticParameters["aphelion"] / syntheticParameters["a"] - 1;
        syntheticParameters["object"] = "asteroid";
        return syntheticParameters;
    },

    checkPerturbedSyntheticObjects() {
        const syntheticObjectsList =
            SyntheticObjectsService.getSyntheticObjectsList();
        if (!syntheticObjectsList.length) {
            return;
        }
        const checkedObjectsList = VisualisationService.getIsChecked();
        const perturbedOrbitObjectsList = VisualisationService.getIsPerturbed();
        syntheticObjectsList.forEach(async (object) => {
            const isShowedObject = checkedObjectsList.includes(
                object.designator
            );
            const isPerturbed = perturbedOrbitObjectsList.includes(
                object.designator
            );
            if (!isPerturbed || !isShowedObject) {
                return;
            }
            await VisualisationService.addObject(object.designator, true);
        });
    },

    getSyntheticObjectApiParameters(syntheticObjectData) {
        const utcDates = TimeFrameService.getUtcDates();
        return {
            name: syntheticObjectData.designator,
            utcStart: utcDates["utcStart"],
            utcEnd: utcDates["utcEnd"],
            step: 1,
            kep_epoch: syntheticObjectData.epoch,
            kep_a: syntheticObjectData.a,
            kep_e: syntheticObjectData.e,
            kep_i: syntheticObjectData.i,
            kep_long: syntheticObjectData.om,
            kep_omega: syntheticObjectData.w,
            kep_mean: syntheticObjectData.ma,
        };
    },

    getSyntheticObjectSovtApiParameters(designator) {
        const { startDateString, endDateString } =
            SynodicCalculationService.getStringTimeframe();
        const defaultParameters = {
            desig: designator,
            initD: startDateString,
            finalD: endDateString,
            planet: true,
            timeStep: 1,
            timeStepUnit: "days",
        };
        const isSyntheticObject =
            SyntheticObjectsService.isSyntheticObject(designator);
        if (!isSyntheticObject) {
            return defaultParameters;
        }
        const syntheticObjectData =
            SyntheticObjectsService.getSyntheticObjectParameters(designator);
        const syntheticObjectParameters = {
            kep_epoch: syntheticObjectData.epoch,
            kep_a: syntheticObjectData.a,
            kep_e: syntheticObjectData.e,
            kep_i: syntheticObjectData.i,
            kep_long: syntheticObjectData.om,
            kep_omega: syntheticObjectData.w,
            kep_mean: syntheticObjectData.ma,
            kep: true,
        };
        return { ...defaultParameters, ...syntheticObjectParameters };
    },

    getSyntheticObjectFvtApiParameters() {
        const syntheticObjectData = SyntheticObjectsService.getSyntheticFvtObjectsList()[0];
        if (!syntheticObjectData) {
            return;
        }
        return {
            name: syntheticObjectData.designator, // object name (use designator with suffix)
            step: 1/24, //timeStep for time step of ephemeris (float)
            flyby_maxdist: SyntheticObjectsService.LD_LIMIT, //flyby_maxdist for clo. app. distance threshold for fly-by computation (float in LD)
            eph_len: 10, //eph_len for ephemeris duration around closest approach (float in days)          
            com_epoch: syntheticObjectData.pericentrePassage, //com_epoch = com_tp =  Time of pericenter passage
            com_q: syntheticObjectData.pericentreDistance * SpacekitService.AU, //com_q for s.m.a. (float in km) = Pericenter distance
            com_e: syntheticObjectData.eccentricity, //com_e for eccentricity (float) = Eccentricity
            com_i: syntheticObjectData.inclination, //com_i for inclination (float in deg) = Inclination
            com_long: syntheticObjectData.ascendingNodeLongitude, //com_long for longitude of ascending node (float in deg) = Longitude of Ascending node
            com_omega: syntheticObjectData.pericentreArgument, //com_omega for argument of perigee (float in deg) = Argument of pericenter
            com_tp: syntheticObjectData.pericentrePassage, //com_tp for time of passage at perigee (float in MJD days) = Time of pericenter passage
        }
    },

    getOvtObjectsInformationsByDesignator(designator) {
        const checkedObjectsList = VisualisationService.getIsChecked();
        const selectedObjectDesignator =
            VisualisationService.getSelectedObjectName();
        const perturbedObjectsList = VisualisationService.getIsPerturbed();
        const isObjectChecked = checkedObjectsList.includes(designator);
        const isObjectSelected = selectedObjectDesignator === designator;
        const isObjectPerturbed = perturbedObjectsList.includes(designator);
        return {
            checkedObjectsList,
            perturbedObjectsList,
            isObjectChecked,
            isObjectSelected,
            isObjectPerturbed,
        };
    },

    checkIsSyntheticObjectFlybyCalculated() {
        const syntheticObjectsList = SyntheticObjectsService.getSyntheticFvtObjectsList();
        if (!syntheticObjectsList.length) {
            return false;
        }
        const syntheticObjectData = syntheticObjectsList[0];
        const selectedObjectData = VisualisationService.getSelectedCloseApproach();
        if (!selectedObjectData || !selectedObjectData.objectName) {
            return false;
        }
        // eslint-disable-next-line
        const {jds, mainBody, ...selectedObjectParameters} = selectedObjectData;
        const parameters = Object.keys(syntheticObjectData);
        return !parameters.some(parameter => syntheticObjectData[parameter] !== selectedObjectParameters[parameter]);
    },

    checkIsNonSyntheticObjectFlybyCalculated() {
        const selectedObjectData = VisualisationService.getSelectedCloseApproach();
        return selectedObjectData && !selectedObjectData.objectName;
    },

    checkIsObjectOnList(syntheticObjectsList, objectDesignator) {
        return syntheticObjectsList.some(
            (asteroid) => asteroid.designator === objectDesignator
        );
    },

    getOvtSyntheticObjectPerturbedOrbitApiUrl() {
        return Config.api.restUrl + Config.api.getOvtSyntheticObjectPerturbed;
    },

    getFvtSyntheticObjectApiUrl() {
        return Config.api.restUrl + Config.api.closeApproachesSyntheticObject;
    },

    getObjectParamtersMap() {
        return {
            epoch: "referenceEpoch",
            q: "perihelionDistance",
            aphelion: "aphelionDistance",
            i: "inclination",
            om: "rightAscension",
            w: "perihelionArgument",
            ma: "meanAnomaly",
            mag: "absoluteMagnitude",
            g: "slopeParameter",
        };
    },

    getObjectParametersUnitLabelMap() {
        return {
            perihelionDistance: "au",
            aphelionDistance: "au",
            inclination: "degrees",
            rightAscension: "degrees",
            perihelionArgument: "degrees",
            meanAnomaly: "degrees",
            absoluteMagnitude: "mag",
        };
    },

    checkIsFlybyAboveLimit() {
        const syntheticObjectData = SyntheticObjectsService.getSyntheticFvtObjectsList()[0];
        if (!syntheticObjectData) {
            return false;
        }
        const {pericentreDistance} = syntheticObjectData;
        return pericentreDistance > SyntheticObjectsService.LD_TO_AU * SyntheticObjectsService.LD_LIMIT;
    },

    setIsSyntheticObjectFlybyAboveLdLimit(isAboveLimit) {
        store.dispatch(CommonActions.setIsSyntheticObjectFlybyAboveLdLimit, isAboveLimit);
    },

    getIsSyntheticObjectFlybyAboveLdLimit() {
        return store.getters[CommonGetters.isSyntheticObjectFlybyAboveLdLimit];
    },

    getSyntheticObjectsConfig() {
        return Config.syntheticObjectsConfiguration;
    },
};

export default SyntheticObjectsService;
