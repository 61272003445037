<template>
    <div v-if="objectDetails && activeObject && !onAir" class="ovt__legend details"
        :class="{ 'with-config': configPanelOpen, 'fvt-legend': tool === 'fvt' }">
        <div class="ovt__legend--label">{{ $t.objectDetails }}</div>
        <button v-if="activeObject._useEphemTable && closeApproachDetected(activeObject.distance.date)" class="cancel"
            @click="openFBVTcloseApproach">
            <i class="icon-alert-triangle" /><span>{{ $t.closeEncounterDetected }}</span>
        </button>
        <div class="d-table">
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.designator }}:</div>
                <div class="d-table-cell">{{ activeObject._options.name }}</div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.diameter }}:</div>
                <div class="d-table-cell">{{ activeObject._options.diam ? activeObject._options.diam.toFixed(1) : '-' }} m
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.absoluteMagnitude }}:</div>
                <div class="d-table-cell">{{ activeObject._options.mag ? activeObject._options.mag.toFixed(1) : '-' }} mag
                </div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.systemTimeMJD }}:</div>
                <div class="d-table-cell">{{ getMJD(activeObject.distance.date) }}</div>
            </div>
            <div v-if="tool === 'ovt' || tool === 'sovt'" class="d-table-row">
                <div class="d-table-cell">{{ $t.systemTime }}:</div>
                <div class="d-table-cell">{{ getDateSimulationTime(activeObject.distance.simulationTime) }}</div>
            </div>
            <div v-if="tool === 'fvt'" class="d-table-row">
                <div class="d-table-cell">{{ $t.systemTime }} UTC:</div>
                <div class="d-table-cell">{{ getDateSimulationTime(activeObject.distance.date) }}</div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.distanceToSun }}:</div>
                <div class="d-table-cell">{{ activeObject.distance.sun }} {{ $t.au }}</div>
            </div>
            <div class="d-table-row">
                <div class="d-table-cell">{{ $t.distanceToEarthCentre }}:</div>
                <div class="d-table-cell">{{ activeObject.distance.earth }} {{ $t.au }}</div>
            </div>
            <div v-if="(tool === 'ovt' || tool === 'sovt')" class="d-table-row">
                <div class="d-table-cell">{{ $t.orbitPropagation }}:</div>
                <div class="d-table-cell">{{ orbitPropagation() }}</div>
            </div>
            <div v-if="(tool === 'ovt' || tool === 'sovt') && activeObject._useEphemTable" class="d-table-row">
                <div class="d-table-cell">{{ $t.orbitReliable }}:</div>
                <div class="d-table-cell">{{ orbitReliable(activeObject.distance.date) }}</div>
            </div>
            <div v-if="tool === 'fvt' && activeObject && activeObject._options.shape" class="d-table-row">
                <div class="d-table-cell">{{ $t.distanceToEarthCentre }}:</div>
                <div class="d-table-cell">{{ (activeObject.distance.earth * 149597870.7).toFixed(2) }} km </div>
            </div>
            <div v-if="tool === 'fvt' && activeObject && activeObject._options.shape" class="d-table-row">
                <div class="d-table-cell">{{ $t.modelSource }}:</div>
                <div v-if="activeObject._options.shape.modelSource === 'generic'" class="d-table-cell">{{
                    activeObject._options.shape.modelSource }}</div>
                <div v-else class="d-table-cell">
                    <button class="link" @click="goToModelSource(activeObject)"><span>{{
                        activeObject._options.shape.modelSource }}</span><i class="icon-external-link"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VisualisationService from '@/services/visualisation.service'
import UtilsService from '@/services/utils.service'
import moment from 'moment'


import CommonActions from '@/store/common/common-actions'


export default {
    name: 'VisualisationObjectLegend',
    data() {
        return {
            object: null,
        }
    },
    computed: {
        settings() {
            return VisualisationService.getSettings();
        },
        configPanelOpen() {
            return VisualisationService.getSelectObjectsShow();
        },
        objectDetails() {
            return this.settings && this.settings.objectsSettings.objectDetails.value;
        },
        activeObject() {
            return VisualisationService.getOrbitActiveObject();
        },
        simulationTime() {
            return VisualisationService.getSimulationTime();
        },
        tool() {
            return VisualisationService.getTool();
        },
        orbitLegend() {
            return this.settings && this.settings.objectsSettings.orbitLegend.value;
        },
        onAir() {
            return VisualisationService.getOrbitRecorderOnAir();
        }
    },
    methods: {
        orbitPropagation() {
            if (this.activeObject._useEphemTable) {
                return this.$t.accurate;
            } else {
                return this.$t.keplerian;
            }
        },
        getDate(date) {
            if (this.tool === 'ovt' || this.tool === 'sovt') {
                return UtilsService.dateToString(date);
            } else {
                return UtilsService.dateToString(date, true);
            }

        },
        getDateSimulationTime(date) {
            if (this.tool === 'ovt' || this.tool === 'sovt') {
                return moment(UtilsService.dateToStringTechnical(date)).format('DD-MM-YYYY');
            } else {
                return moment(UtilsService.dateToStringTechnicalFVTInit(date, true)).format('DD-MM-YYYY HH:mm');
            }
        },
        getMJD(date) {
            if (this.tool === 'ovt' || this.tool === 'sovt') {
                const currentDate = parseInt(UtilsService.dateToJulian(VisualisationService.getCurrentDate()) - 2400000.5);
                return currentDate;
                //return parseInt(UtilsService.dateToJulian(date)) - 2400000;
            } else {
                return (UtilsService.dateToJulian(date) - 2400000.5).toFixed(5);
            }
        },
        orbitReliable(date) {
            const mjd = parseInt(UtilsService.dateToJulian(date)) - 2400000;
            if (this.activeObject._options.uncTable)
                return this.activeObject._options.uncTable.indexOf(mjd) > -1 ? this.$t.no : this.$t.yes;
            else return;
        },
        closeApproachDetected(date) {
            if (this.tool === 'fvt') return false;
            const mjd = parseInt(UtilsService.dateToJulian(date)) - 2400000;
            const closeApproaches = this.activeObject._options.closeApproachTable
            let closeApproachDetected = null;
            if (closeApproaches)
                closeApproachDetected = closeApproaches.indexOf(mjd) > -1;
            return closeApproachDetected;
        },
        createHref(object) {
            const source = object._options.shape.modelSource;
            const designator = object._options.designator;

            const services = {
                '3d-asteroids': 'https://3d-asteroids.space/asteroids/',
                'damit': 'https://astro.troja.mff.cuni.cz/projects/damit/?q=',
            }

            const url = services[source];
            return url + designator;
        },
        goToModelSource(object) {
            const url = this.createHref(object);
            window.open(url, '_blank').focus();
        },
        openFBVTcloseApproach() {
            const flyByNewTab = window.open('/fvt', '_blank');
            flyByNewTab.store = this.$store; // Pass the Vuex store to the new window            
            flyByNewTab.store.dispatch(CommonActions.setCloseApproachDetected, true);
        }
    },
}
</script>

<style></style>
