<template>
    <div class="ovt__objects-table">
        <div class="ovt__objects-table__box">
            <button class="link ovt__objects-table__button" @click="configureObjects()">
                <i class="icon-settings" /><span>{{
                    $t.configureYourOwnListOfObjects
                }}</span>
            </button>
        </div>
        <div v-if="finalAsteroidsList.length" class="ovt__objects-table__content" :class="{ loading }">
            <div class="inner" :class="{ 'no-scroll': finalAsteroidsList.length < 5 }">
                <div class="d-table-row headers">
                    <div class="d-table-cell">{{ $t.object }}</div>
                    <div class="d-table-cell-empty"></div>
                    <div class="d-table-cell orbit-switcher label">
                        {{ $t.orbitSwitchLabel }}
                        <Tooltip position="left" :tooltipText="$keys.t.switchOrbit"><i class="icon-info" /></Tooltip>
                    </div>
                    <div class="d-table-cell">{{ $t.show }}</div>
                </div>
                <div class="d-table-row item" v-for="(item, index) of finalAsteroidsList" :key="index"
                    :class="{ 'item-highlight': isSelectedObject(item) }">
                    <div class="d-table-cell">
                        <input type="radio" :disabled="getItemDisabled(item)" :id="'radio_' + item.id" :value="item.id" name="radioGroup"
                            :checked="isSelectedObject(item)" @change="handleSelection(item)" />
                    </div>
                    <div class="d-table-cell">
                        <span @click="handleSelection(item)" :style="{ color: getItemColor(item) }">
                            {{ displayName(item) }}
                        </span>
                    </div>
                    <div class="d-table-cell orbit-switcher field">
                        <input class="toggle-switch" :id="`perturbedOrbit_${item.id}`" :checked="isPerturbedOn(item)"
                            :disabled="getItemDisabled(item, 'toggle-switch')" type="checkbox" @click="
                                orbitSwitchClick(
                                    item,
                                    $event.target.checked,
                                    $event
                                )
                                " />
                        <label class="toggle-switch__button" :class="{disabled: getItemDisabled(item, 'toggle-switch')}"
                            :for="`perturbedOrbit_${item.id}`"></label>
                    </div>
                    <div class="d-table-cell input">
                        <input type="checkbox"  :id="item.name" :checked="isObjectVisible(item)"
                            :disabled="!isObjectCalculated(item)" @change="handleObjectVisibility(item)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tooltip from "@/components/common/Tooltip";
import config from "@/constants/config";
import ObjectsService from "@/services/objects.service";
import PopupService from "@/services/popup.service";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import UtilsService from "@/services/utils.service";
import VisualisationService from "@/services/visualisation.service";

export default {
    name: "SynodicOrbitObjectsList",
    components: {
        Tooltip,
    },
    computed: {
        finalAsteroidsList() {
            return ObjectsService.getFinalAsteroidsList();
        },

        visibleRealSynodicObjectsList() {
            return SynodicCalculationService.getVisibleRealSynodicObjectsList();
        },

        selectedSynodicObjectName() {
            return VisualisationService.getSelectedSynodicObjectName();
        },

        loading() {
            return VisualisationService.loadingObjectsEphemerides().length;
        },
    },
    methods: {
        getItemDisabled(item, type) {
            if (((item.code == "non-NEO" || item.keplerianOnly) && type == 'toggle-switch')  || (item.sourceType.name == "NEOCP")) {
                return true;
            } else 
                return false;
        },
        getItemColor(item) {
            const settings = config.visualisation.settings;
            if (item.code == "non-NEO") {
                return settings.nonNEAcolor;
            } else if (item.sourceType.name == "NEOCP"){
                return settings.NEOCPcolor;
            }
        },
        displayName(item) {
            return ObjectsService.objectNumberAndName(item);
        },

        configureObjects() {
            PopupService.show({
                component: "PopupObjectsConfiguration",
            });
        },

        handleSelection(item) {
            if (item.sourceType.name != "NEOCP"){
                const designator = this.getDesignator(item);
                VisualisationService.selectSynodicObject(designator);
            }
        },

        isSelectedObject(item) {
            return this.getDesignator(item) === this.selectedSynodicObjectName;
        },

        handleObjectVisibility(item) {
            const designator = this.getDesignator(item);
            SynodicCalculationService.handleObjectVisibility(designator);
        },

        isObjectVisible(item) {
            const designator = this.getDesignator(item);
            return this.visibleRealSynodicObjectsList.includes(designator);
        },

        isObjectCalculated(item) {
            const designator = this.getDesignator(item);
            const calculatedObjectList = SynodicCalculationService.getCalculatedRealSynodicObjectsList();
            return !!UtilsService.findItemInObjectList(
                "designator",
                designator,
                calculatedObjectList
            );
        },

        async orbitSwitchClick(item, isChecked, event) {
            let isPerturbedAvailable = true;
            const designator = this.getDesignator(item);
            const isVisible = SynodicCalculationService.getVisibleRealSynodicObjectsList().includes(
                designator
            );
            if (isChecked && isVisible) {
                event.preventDefault();
            }
            if (!isChecked && isVisible) {
                SynodicCalculationService.switchOrbitToKeplerian(designator);
            }
            if (isChecked && isVisible) {
                isPerturbedAvailable = await SynodicCalculationService.switchOrbitToPerturbed(
                    designator
                );
            }
            isVisible &&
                SynodicCalculationService.checkIsFocusOnObject(designator);
            const perturbedOrbitList = UtilsService.deepCopy(
                SynodicCalculationService.getPerturbedSynodicOrbitList()
            );
            if (isChecked && isPerturbedAvailable) {
                perturbedOrbitList.push(designator);
            }
            if (!isChecked) {
                const objectIndex = perturbedOrbitList.indexOf(designator);
                objectIndex !== -1 && perturbedOrbitList.splice(objectIndex, 1);
            }
            SynodicCalculationService.setPerturbedSynodicOrbitList(
                perturbedOrbitList
            );
            !isPerturbedAvailable &&
                SynodicCalculationService.showNoPerturbedInfo(designator);
        },

        isPerturbedOn(item) {
            const designator = this.getDesignator(item);
            return SynodicCalculationService.getPerturbedSynodicOrbitList().includes(
                designator
            );
        },

        getDesignator(object) {
            return (object.number ? object.number : object.name).toString();
        },
    },
    watch: {
        finalAsteroidsList: {
            deep: true,
            handler(newVal) {
                SynodicCalculationService.verifyCalculatedRealSynodicObjectsList(
                    newVal,
                    this.selectedSynodicObjectName
                );
            },
        },
    },
    mounted() {
        ObjectsService.setUserDefinedObjects(true);

        const queryDesignator = this.$route.query.object;
        if (queryDesignator) {
            ObjectsService.findAsteroidByPhrase(queryDesignator, true, 'sovt')
        }
    },
};
</script>

<style>
.d-table-cell-empty {
    width: 50px;
}
</style>
