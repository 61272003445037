<template>
    <div class="sovt">
        <SynodicOrbitObjectsConfiguration />
        <VisualisationSimulation :tool="tool">
            <SynodicOrbitLegend />
            <SynodicOrbitObjectDetails />
        </VisualisationSimulation>
        <VisualisationAxesViewer />
        <SynodicOrbitCharts />
        <VisualisationRecorder />
        <VisualisationSettings :tool="tool" />
        <SynodicOrbitTimeline />
    </div>
</template>

<script>
import moment from "moment";
import { THREE } from "spacekit.js";
import SynodicOrbitCharts from "@/components/sovt/SynodicOrbitCharts.vue";
import SynodicOrbitLegend from "@/components/sovt/SynodicOrbitLegend";
import SynodicOrbitObjectDetails from "@/components/sovt/SynodicOrbitObjectDetails";
import SynodicOrbitObjectsConfiguration from "@/components/sovt/SynodicOrbitObjectsConfiguration.vue";
import SynodicOrbitTimeline from "@/components/sovt/SynodicOrbitTimeline";
import VisualisationAxesViewer from "@/components/common/visualisation/AxesViewer";
import VisualisationRecorder from "@/components/common/visualisation/Recorder";
import VisualisationSettings from "@/components/common/visualisation/Settings";
import VisualisationSimulation from "@/components/common/visualisation/Simulation";
import ObjectsService from "@/services/objects.service";
import SynodicCalculationService from "@/services/synodic-calculation.service";
import TimeFrameService from "@/services/timeframe.service";
import UtilsService from "@/services/utils.service";
import VisualisationService from "@/services/visualisation.service";
import CommonActions from "@/store/common/common-actions";

export default {
    name: "OrbitVisualisationTool",
    components: {
        SynodicOrbitObjectsConfiguration,
        VisualisationRecorder,
        SynodicOrbitLegend,
        SynodicOrbitObjectDetails,
        VisualisationSettings,
        SynodicOrbitTimeline,
        VisualisationSimulation,
        VisualisationAxesViewer,
        SynodicOrbitCharts,
    },
    props: {
        tool: String,
    },
    data() {
        return {
            currentStartTime: null,
            currentEndTime: null,
            currentDate: null,
        };
    },
    computed: {
        isDetectionPolarVisible() {
            return VisualisationService.getSettings().objectsSettings.detectionPolar.value;
        },

        isElongationConeVisible() {
            return VisualisationService.getSettings().objectsSettings.elongationCone.value;
        },

        selectedTimeframe() {
            return TimeFrameService.getTimeFrame().converted;
        },

        simulationTime() {
            return TimeFrameService.getSimulationTime();
        },

        selectedCalculatedObject() {
            return SynodicCalculationService.getSelectedCalculatedObject();
        },

        settings() {
            return VisualisationService.getSettings();
        },

        synodicPlanets() {
            return this.settings && this.settings.objectsSettings.synodicPlanets.value;
        },

        synodicPlanetsNames() {
            return this.settings && this.settings.objectsSettings.synodicPlanetsNames.value;
        },

        synodicPlanetsNamesColor() {
            return this.settings && this.settings.objectsSettings.synodicPlanetsNames.color;
        },

        synodicPlanetsNamesSize() {
            return this.settings && this.settings.objectsSettings.synodicPlanetsNamesSize.value;
        },

        synodicPlanetsOrbits() {
            return this.settings && this.settings.objectsSettings.synodicPlanetsOrbits.value;
        },

        synodicPlanetsOrbitsColor() {
            return this.settings && this.settings.objectsSettings.synodicPlanetsOrbits.color;
        },

        earthOnScale() {
            return this.settings && this.settings.objectsSettings.earthOnScale.value;
        },

        elongationCone() {
            return this.settings && this.settings.objectsSettings.elongationCone.value;
        },

        elongationConeAngle() {
            return this.settings && this.settings.objectsSettings.elongationConeProperties.children.coneAngle.value;
        },

        elongationConeTransparency() {
            return this.settings && this.settings.objectsSettings.elongationConeProperties.children.transparency.value;
        },

        elongationConeColor() {
            return this.settings && this.settings.objectsSettings.elongationConeProperties.children.color.value;
        },

        synodicPerturbedOrbitList() {
            return SynodicCalculationService.getPerturbedSynodicOrbitList();
        },

        selectedSynodicObjectName() {
            return VisualisationService.getSelectedSynodicObjectName();
        }
    },
    methods: {
        formatDate(newDate) {
            return moment.utc(newDate).format("YYYY-MM-DD");
        },

        adjustEarthAngle(oldDateJd, newDateJd) {
            const { period } = VisualisationService.getMainBodyParameters("earth");
            const earth = UtilsService.findItemInObjectList(
                "_id",
                SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME,
                window["vt"].planets
            );
            const fakeMoon = UtilsService.findItemInObjectList(
                "_id",
                SynodicCalculationService.EARTH_FAKE_MOON_NAME,
                window["vt"].planets
            );
            if (!fakeMoon) {
                return;
            }
            const fakeMoonPosition = fakeMoon.getPosition(newDateJd);
            const earthTargetVector = new THREE.Vector3(fakeMoonPosition[0], fakeMoonPosition[1], fakeMoonPosition[2]);
            const oldZAngle = earth._object3js.rotation.z;
            earth._object3js.lookAt(earthTargetVector);
            const newZAngle = earth._object3js.rotation.z;
            const deltaZAngle = newZAngle - oldZAngle;
            const zAngle = ((2 * Math.PI) / period) * (newDateJd - oldDateJd) - deltaZAngle;
            earth._object3js.rotateZ(zAngle);
        },

        checkSelectedSynodicObject() {
            const selectedSynodicObjectName = VisualisationService.getSelectedSynodicObjectName();
            if (!selectedSynodicObjectName) {
                return;
            }
            const synodicPerturbedOrbitList = SynodicCalculationService.getPerturbedSynodicOrbitList();
            if (!synodicPerturbedOrbitList.length) {
                return;
            }
            const isSelectedPerturbed = synodicPerturbedOrbitList.includes(selectedSynodicObjectName);
            if (!isSelectedPerturbed) {
                return;
            }
            VisualisationService.updateSetting({
                group: "objectsSettings",
                change: {
                    option: "orbitRange",
                    property: "value",
                    value: false,
                },
            });
        },
    },
    watch: {
        selectedTimeframe(timeframe) {
            if (
                (this.currentStartTime !== timeframe.start && timeframe.start) ||
                (this.currentEndTime !== timeframe.end && timeframe.end)
            ) {
                this.currentStartTime = timeframe.start;
                this.currentEndTime = timeframe.end;
                SynodicCalculationService.createTransformedEarthObject();
                SynodicCalculationService.verifyCalculatedRealSynodicObjectsList(ObjectsService.getFinalAsteroidsList());
                SynodicCalculationService.recalculateVisibleSynodicOrbits();
                SynodicCalculationService.recalculateTransformedPlanets();
            }
        },

        simulationTime(newDate, oldDate) {
            const oldDateJd = UtilsService.dateToJulian(UtilsService.dateToStringTechnical(oldDate, true));
            const newDateJd = UtilsService.dateToJulian(UtilsService.dateToStringTechnical(newDate, true));
            const date = this.formatDate(newDate);
            this.adjustEarthAngle(oldDateJd, newDateJd);
            if (this.isElongationConeVisible) {
                SynodicCalculationService.adjustElongationConePosition();
            }
            if (this.currentDate === date && !this.earthOnScale) {
                return;
            }
            SynodicCalculationService.verifyVisiblePerturbedObjects(date);
            this.currentDate = date;
            if (!this.selectedCalculatedObject || !this.isDetectionPolarVisible) {
                return;
            }
            SynodicCalculationService.adjustDetectionPolarObjectPosition();
            SynodicCalculationService.adjustDetectionPolarMergedPosition();
        },

        synodicPlanets(isVisible) {
            SynodicCalculationService.toggleSynodicPlanets(isVisible);
        },

        synodicPlanetsNames(isVisible) {
            SynodicCalculationService.toggleSynodicPlanetsNames(isVisible);
        },

        synodicPlanetsNamesColor(color) {
            SynodicCalculationService.updateSynodicPlanetLabel('color', color);
        },

        synodicPlanetsNamesSize(fontSize) {
            SynodicCalculationService.updateSynodicPlanetLabel('fontSize', fontSize);
        },

        synodicPlanetsOrbits(isVisible) {
            SynodicCalculationService.toggleSynodicPlanetsOrbits(isVisible)
        },

        synodicPlanetsOrbitsColor(color) {
            SynodicCalculationService.updateSynodicPlanetsOrbitsColor(color);
        },

        earthOnScale(isEarthOnScale) {
            SynodicCalculationService.toggleEarthOnScale(isEarthOnScale);
        },

        elongationCone(isVisible) {
            SynodicCalculationService.toogleElongationCone(isVisible);
        },

        elongationConeAngle(halfAngle) {
            SynodicCalculationService.changeElongationConeAngle(halfAngle);
        },

        elongationConeTransparency(transparency) {
            SynodicCalculationService.changeElongationConeTransparency(transparency);
        },

        elongationConeColor(color) {
            SynodicCalculationService.changeElongationConeColor(color);
        },

        synodicPerturbedOrbitList() {
            this.checkSelectedSynodicObject();
        },

        selectedSynodicObjectName() {
            this.checkSelectedSynodicObject();
        }
    },
    async mounted() {
        this.$store.dispatch(CommonActions.setTool, "sovt");
        this.$store.dispatch(CommonActions.setToolTitle, this.$keys.titles.sovt);
        const impactorsList = await SynodicCalculationService.getImpactorsList();
        SynodicCalculationService.setImpactors(impactorsList);
        SynodicCalculationService.verifyCalculatedRealSynodicObjectsList(ObjectsService.getFinalAsteroidsList());
        this.checkSelectedSynodicObject();
    },
};
</script>

<style lang="scss">
@import "@/styles/common/visualisation.scss";
@import "@/styles/sovt/general.scss";
</style>
