import JulianDate from 'julian-date'
import moment from 'moment'
import axios from 'axios'
import Keys from '@/constants/keys'
import SunCalc3 from 'suncalc3'
import TimeFrameService from '@/services/timeframe.service'
import ObservatoryService from '@/services/observatory.service'
import LongtermService from '@/services/longterm.service'

const UtilsService = {
    dateToISOString(date) {
        return date.toISOString();
    },

    dateToString(date, dateTime = false) {
        if (!dateTime) {
            return moment.utc(date).format('DD-MM-YYYY');
        } else {
            return moment.utc(date).format('DD-MM-YYYY HH:mm');
        }
    },

    isDateValid(date) {
        const momentDate = moment(date);
        return momentDate.isValid();
    },

    dateToStringTechnical(date, dateTime = false) {
        if (!dateTime) {
            return moment(date).format('YYYY-MM-DD');
        } else {
            return moment(date).format('YYYY-MM-DDTHH:mm');
        }
    },

    dateToStringTechnicalFVTInit(date, dateTime = false) {
        if (!dateTime) {
            return moment.utc(date).format('YYYY-MM-DD');
        } else {
            return moment.utc(date).format('YYYY-MM-DDTHH:mm');
        }
    },

    dateStringToObject(date) {
        return new Date(date);
    },

    dateDifferenceInDays(date1, date2) {
        return Math.ceil((date1.getTime() - date2.getTime()) / (1000 * 3600 * 24)); 
    },

    dateDifferenceInMinutes(date1, date2) {
        return Math.ceil((date1.getTime() - date2.getTime()) / (1000 * 60)); 
    },

    datePrevDay(date) {
        const prevDay = new Date(date);
        prevDay.setDate(prevDay.getDate() - 1);
        return UtilsService.dateToStringTechnical(prevDay);
    },
    
    dateNextDay(date) {
        const nextDay = new Date(date);
        nextDay.setDate(nextDay.getDate() + 1);
        return UtilsService.dateToStringTechnical(nextDay);
    },

    addMinutes(date, minutes) {
        return new Date(date.getTime() + minutes*60000);
    },

    dateNext10Minutes(date) {
        const currentDate = new Date(date);
        const nextStep = UtilsService.addMinutes(currentDate, 10);
        return UtilsService.dateToStringTechnical(nextStep, true);
    },

    dateToJulian(date) {
        return new JulianDate(new moment.utc(date)).julian();
    },

    julianToDate(julian, useHtmlBr) {
        const j = new JulianDate().julian(julian);
        if (useHtmlBr) {
            return `${j.getDate().toLocaleDateString()}<br />${j.getDate().toLocaleTimeString()}`;
        } else {
            return j.getDate().toLocaleString();
        }
    },

    julianToDateObject(julian) {
        const julianDate = julian < 240000.5 ? julian + 2400000.5 : julian;
        const j = new JulianDate().julian(julianDate);
        return j.getDate();
    },

    julianToDatestring(julian) {
        const date = new JulianDate().julian(julian).getDate();
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    },

    julianToTimestamp(julian) {
        const j = new JulianDate().julian(julian);
        return j.getDate().getTime();
    },

    momentUtcDate(date, format = false) {
        if (format) {
            return moment.utc(date).locale(navigator.language).format('DD-MM-YYYY HH:mm');
        }
        return moment.utc(date);
    },

    momentLocalDate(date, format = false) {
        if (format) {
            return moment(date).locale(navigator.language).format('DD-MM-YYYY HH:mm');
        }
        return moment(date);
    },

    momentInLocalTimeDate(date, format = false) {
        const timezoneShift = TimeFrameService.getTimezoneShift(date);
        if (format) {
            return moment.utc(date).add(timezoneShift, 'hours').locale(navigator.language).format('DD-MM-YYYY HH:mm');
        }
        return moment.utc(date).add(timezoneShift, 'hours');
    },  

    isTheSameDay(timestamp1, timestamp2) {
        const date1 = moment.utc(timestamp1);
        const date2 = moment.utc(timestamp2);
        return date1.isSame(date2, 'day');
    },

    normalize(min, max) {
        var delta = max - min;
        if (!delta) {
            return function () {
                return 0.5;
            };
        }
        return function (val) {
            const normalized = (val - min) / delta;
            if (normalized > 1) return 1;
            if (normalized < 0) return 0;
            return (val - min) / delta;
        };
    },

    normalizeAlt(val, min, max) { 
        return Math.min(Math.max(val,min),max); 
    },

    copyToClipboard(text) {
        const textholder = document.createElement('textarea');
        document.body.appendChild(textholder);
        textholder.value = text;
        textholder.select();
        document.execCommand('copy');
        document.body.removeChild(textholder);
    },

    downloadAsciiFile(fileContent, fileName) {
        const content = new TextEncoder().encode(fileContent);
        const downloadLink = document.createElement('a');
        const downloadFile = new Blob([content], {type: 'text/plain;charset=utf-8'});
        const date = moment().toISOString(true);
        downloadLink.href = URL.createObjectURL(downloadFile);
        downloadLink.download = fileName + '_' + date + '.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
    },

    getBase64Image(img) {
        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        return dataURL;
    },

    invertImage(imgData) {
        return new Promise((resolve, reject) => {
            const image = document.createElement('img');
            image.src = imgData;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext('2d');
                ctx.filter = 'invert(1)';
                ctx.drawImage(image, 0, 0);
                resolve(canvas.toDataURL('image/png'));
            }
            image.onerror = error => {
                reject(error)
            }
        });
    },

    hexToRgb(hex) {
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return {
                r: (c>>16)&255,
                g: (c>>8)&255,
                b: c&255
            };
        }
        throw new Error('Bad Hex');
    },

    hexToRgbA(hex, a){
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+a+')';
        }
        throw new Error('Bad Hex');
    },

    astronomicalTwilight(type, date) {
        if (!date) {
            return 'SUN_HIGH';
        }

        const observatory = ObservatoryService.getSelectedObservatory();
        if (observatory.code === '500') {
            return 'SUN_HIGH';
        }

        const latitude = observatory.latitude*1;
        const longitude = observatory.longitude*1;
        const times = SunCalc3.getSunTimes(new Date(date), latitude, longitude, 0, false, true);
        const key = `astronomical${type}`;
        const time = times[key] ? times[key].value : null;

        if (!time || isNaN(time)) {
            return 'SUN_HIGH';
        }

        const timezoneShift = TimeFrameService.getTimezoneShift(date);
        const utc = moment.utc(time).format('HH:mm');
        const local = moment.utc(time).add(timezoneShift, 'hours').format('HH:mm');
        return {
            utc,
            local
        }
    },

    getElementOffset(element) {
        let x = 0;
        let y = 0;
        while( element && !isNaN( element.offsetLeft ) && !isNaN( element.offsetTop ) ) {
            x += element.offsetLeft - element.scrollLeft;
            y += element.offsetTop - element.scrollTop;
            element = element.offsetParent;
        }
        return {
            top: y,
            left: x,
        }
    },

    parseParams(paramsObj) {
        let params = '?';
        if (typeof paramsObj !== 'object' || !paramsObj) return;
        for (const param in paramsObj) {
            if (params !== '?') {
                params += '&';
            }
            params += param + "=" + paramsObj[param];
        }
        return params;
    },

    convertToAltitudeAndAzimuth(dt, pos, loc) {
        // dt: datetime,
        // pos: celestial coordinates [ra,dec]
        // loc: location [lat,lng] 

        const deg2rad = Math.PI/180;
        let ha = UtilsService.getMST(dt, loc[1]) - pos[0];
        if (ha < 0) ha = ha + 360;

        ha = ha * deg2rad;
        const dec = pos[1] * deg2rad;
        const lat = loc[0] * deg2rad;

        const alt = Math.asin(Math.sin(dec) * Math.sin(lat) + Math.cos(dec) * Math.cos(lat) * Math.cos(ha));
        let az = Math.acos((Math.sin(dec) - Math.sin(alt) * Math.sin(lat)) / (Math.cos(alt) * Math.cos(lat)));

        if (Math.sin(ha) > 0) az = Math.PI * 2 - az;
        
        return [alt / deg2rad, az / deg2rad, 0];
    },

    convertToRaAndDec(dt, hor, loc) {
        // dt: datetime,
        // hor: horizon coordinates [alt,az]
        // loc: location [lat,lng]  
    
        const deg2rad = Math.PI/180;
        var alt = hor[0] * deg2rad;
        var az = hor[1] * deg2rad;
        var lat = loc[0] * deg2rad;
            
        var dec = Math.asin((Math.sin(alt) * Math.sin(lat)) + (Math.cos(alt) * Math.cos(lat) * Math.cos(az)));
        var ha = ((Math.sin(alt) - (Math.sin(dec) * Math.sin(lat))) / (Math.cos(dec) * Math.cos(lat)));
        
        if (ha < -1) ha = -1;
        if (ha > 1) ha = 1;
        ha = Math.acos(ha);
        ha = ha / deg2rad;
        if (hor[1] < 180) ha = -ha;

        var ra = UtilsService.getMST(dt, loc[1]) - ha;
        if (ra < 0) ra += 360;
        if (ra > 360) ra -= 360;
            
        return [ra, dec / deg2rad, 0];
    },

    getMST(dt, lng) {
        let yr = dt.getUTCFullYear();
        let mo = dt.getUTCMonth() + 1;
        const dy = dt.getUTCDate();
        const h = dt.getUTCHours();
        const m = dt.getUTCMinutes();
        const s = dt.getUTCSeconds();

        if ((mo == 1)||(mo == 2)) {
            yr = yr - 1;
            mo = mo + 12;
        }

        const a = Math.floor(yr / 100);
        const b = 2 - a + Math.floor(a / 4);
        const c = Math.floor(365.25 * yr);
        const d = Math.floor(30.6001 * (mo + 1));

        // days since J2000.0
        const jd = b + c + d - 730550.5 + dy + (h + m/60.0 + s/3600.0)/24.0;
        
        // julian centuries since J2000.0
        const jt = jd/36525.0;

        // the mean sidereal time in degrees
        let mst = 280.46061837 + 360.98564736629*jd + 0.000387933*jt*jt - jt*jt*jt/38710000 + lng;

        // in degrees modulo 360.0
        if (mst > 0.0) 
            while (mst > 360.0) mst = mst - 360.0;
        else
            while (mst < 0.0)   mst = mst + 360.0;
        return mst;
    },

    rgbToHex(rgb) { 
        let hex = Number(rgb).toString(16);
        if (hex.length < 2) {
             hex = '0' + hex;
        }
        return hex+hex+hex;
    },

    randomHexColor(number = true, withHash = false) {
        let hex = Math.floor(Math.random()*16777215).toString(16);
        if (hex.length === 5) {
            hex += '0';
        }
        if (hex.length === 4) {
            hex += '00';
        }
        if (number) {
            return Number('0x' + hex);
        } else if (withHash) {
            return '#' + hex;
        } else {
            return hex;
        }
    },

    degToDms(deg, raOrDec) {
        let degreesSign = '°';
        let minutesSign = '\'';
        let secondsSign = '"';
        let decimalFixed = 2;
        if (raOrDec === 'ra') {
            deg = deg / 15;
            degreesSign = 'h';
            minutesSign = 'm';
            secondsSign = 's';
            decimalFixed = 3;
        } else if (raOrDec === 'dec') {
            degreesSign = '°';
        }
        let absolute = Math.abs(deg);
        let degrees = Math.floor(absolute);
        let minutesNotTruncated = (absolute - degrees) * 60;
        let minutes = Math.floor(minutesNotTruncated);
        let seconds = ((minutesNotTruncated - minutes) * 60).toFixed(decimalFixed);
        let direction = '';
        direction = deg >= 0 ? '' : '-';
        return direction + degrees + degreesSign + ' ' + minutes + minutesSign + ' ' + seconds + secondsSign + ' ';
    },

    formatDegrees(item, dmsFormat, raOrDec = '') {
        if (!dmsFormat) {
            if (raOrDec) {
                return (item * 1).toFixed(6) + '°';
            } else {
                return (item * 1).toFixed(1) + '°';
            }
        } else {
            return UtilsService.degToDms(item, raOrDec);
        }
    },

    arrayReducer(array, mod) {
        let i = array.length;
        while (i--) {
            (i + 1) % mod !== 0 && array.splice(i, 1);
        }   
    },

    dateAddDays(date, days) {
        const targetDate = new Date(date);
        targetDate.setDate(targetDate.getDate() + days);
        return targetDate;
    },

    dateAddYears(date, years) {
        const targetDate = new Date(date);
        targetDate.setYear(targetDate.getFullYear() + years);
        return targetDate;
    },

    prepareErrorMessage(error) {
        if (error) {
            if (error.response && error.response.data) {
                const text = error.response.data.split('<b>Message</b>')[1].split('</p>')[0];
                if (text.length) {
                    return `Error: ${text}`;
                }
            } else if (axios.isCancel(error)) {
                return Keys.t.calculationsStopped;
            }
        }
        return error;
    },

    reduceDensity(values) {
        const density = LongtermService.getTimestampDensity();
        const valuesLength = values.length;
        const valuesReduced = [];
        
        if (valuesLength <= density) {
            return values;
        } else {
            const factor = valuesLength / density;
            let factorMod = 0;

            for (const [index, timestamp] of values.entries()) {
                if (!index || index === Math.round(factorMod * factor)) {
                    valuesReduced.push(timestamp);
                    factorMod += 1;
                }
            }

            return valuesReduced;
        }
    },
    clamp(value, min, max) {
        return value > max ? max : value < min ? min : value;
    },   
    getMinutesDifference(startDate, endDate) {
        const millisecondsPerMinute = 1000 * 60;
        
        const startTimestamp = startDate.getTime();
        const endTimestamp = endDate.getTime();
        
        const timeDifference = Math.abs(endTimestamp - startTimestamp);
        const minutesDifference = Math.floor(timeDifference / millisecondsPerMinute);
        
        return minutesDifference;
    },     
    hexColor(color) {
        return Number('0x' + color.slice(1));
        
    },      
    applyOpacityToHexColor(hexColor, opacity) {
        // Remove the # symbol if present
        hexColor = hexColor.replace('#', '');
      
        // Convert the hexadecimal color to RGB format
        var r = parseInt(hexColor.substring(0, 2), 16);
        var g = parseInt(hexColor.substring(2, 4), 16);
        var b = parseInt(hexColor.substring(4, 6), 16);
      
        // Convert the opacity to the range of 0 to 1
        opacity = parseFloat(opacity);
      
        // Apply the opacity to the RGB values
        var appliedR = Math.round((1 - opacity) * 255 + opacity * r);
        var appliedG = Math.round((1 - opacity) * 255 + opacity * g);
        var appliedB = Math.round((1 - opacity) * 255 + opacity * b);
      
        // Convert the applied RGB values back to hexadecimal format
        var appliedHexColor = '#' + (appliedR).toString(16).padStart(2, '0') +
          (appliedG).toString(16).padStart(2, '0') +
          (appliedB).toString(16).padStart(2, '0');
      
        return appliedHexColor; 
      },
    
    createDateYearsFromNow(yearsDiffference) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return new Date(
            currentDate.setFullYear(currentYear + yearsDiffference)
        );
    },

    checkObjectPropertiesHasValue(object) {
        return (
            Object.values(object).filter((value) => value || value === 0)
                .length === Object.values(object).length
        );
    },

    checkAnyObjectPropertyHasValue(object) {
        return (Object.values(object).some((value) => value || value === 0));
    },

    convertHexColorToHexNumber(hexColorString) {
        return parseInt(hexColorString.slice(1), 16);
    },

    findItemInObjectList(propertyName, propertyValue, objectList) {
        return objectList.find(item => item[propertyName] === propertyValue);
    },

    findItemIndexInObjectList(propertyName, propertyValue, objectList) {
        return objectList.findIndex(item => item[propertyName] === propertyValue);
    },

    deepCopy(object) {
        return JSON.parse(JSON.stringify(object));
    },

    countDaysDifference(startDate, endDate) {
        const dayMilliseconds = 24 * 60 * 60 * 1000;
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        const differenceMilliseconds = Math.abs(endDate - startDate);
        const differenceDays = Math.round(differenceMilliseconds / dayMilliseconds);
        return differenceDays;
    }
}


export default UtilsService;
