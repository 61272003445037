import axios from "axios";
import * as Spacekit from "spacekit.js";
import { THREE } from "spacekit.js";
import moment from "moment";
import HUDService from "@/services/hud.service";
import ObjectsService from "./objects.service";
import PopupService from "@/services/popup.service";
import SpacekitService from "@/services/spacekit.service";
import SynodicChartService from "@/services/synodic-chart.service";
import SyntheticObjectsService from "./synthetic-objects.service";
import TimeFrameService from "@/services/timeframe.service";
import UtilsService from "@/services/utils.service";
import VisualisationService from "@/services/visualisation.service";
import store from "@/store";
import CommonGetters from "@/store/common/common-getters";
import CommonActions from "@/store/common/common-actions";
import Keys from "@/constants/keys";
import Config from "@/constants/config";

const SynodicCalculationService = {
    EARTH_PERIOD: 365.25,
    EARTH_ROTATION_AXIS_ANGLE: 23.4,
    PROPAGATION_TIME_STEP_DAY: 1,
    DETECTION_POLAR_STEP_ANGLE: 1,
    AVERAGED_EARTH_ORBIT_RADIUS: 1,
    AVERAGED_EARTH_ORBIT_STEP_ANGLE: 1,
    EARTH_ON_SCALE_FACTOR: 0.006692096026745154,
    EARTH_ON_SCALE_ASTEROID_FACTOR: 0.000852708,
    ASTEROID_ZOOM_FACTOR: 0.4,
    TRANSFORMED_EARTH_OBJECT_NAME: "earth_transformed",
    TRANSFORMED_SPACE_OBJECT_SUFFIX: "_transformed",
    TRANSFORMED_SPACE_OBJECT_SUFFIX_PERTURBED: "_transformed_perturbed",
    EARTH_PLACEHOLDER_NAME: "earth_placeholder",
    EARTH_FAKE_MOON_NAME: "earth_fake_moon",
    LABEL_COLOR: 0xffffff,
    LABEL_SIZE: 13,
    EARTH_OBJECT_DEFAULT_OPTIONS: {
        ...Spacekit.SpaceObjectPresets.EARTH,
        name: "Earth",
        textureUrl: "/static/textures/detailed/earth.jpg",
        radius: 0.0063710084,
        rotation: {
            enable: false,
        },
    },
    FAKE_MOON_DEFAULT_OPTIONS: {
        ephemData: {
            // Define orbit shape
            a: 0.43273864224742586 / 2, // Radius of the vertical cone, calculated from the cone formulae, for h=0.5au
            e: 0,
            i: Math.PI, // Flip of the orbit rotation direction

            // Define the orientation of the orbit
            om: -Math.PI / 2, // Rotate reference system to make it synodic (as other elements)
            w: 0,
            ma: 0,

            // Object init position
            epoch: 2451623.815972, // 20.03.2000 07:35 as a starting position, X-axis 90[deg], Y-axis 23.4[deg] to the screen
            period: 365.242189, // Tropical year period
        },
        data: {
            textureUrl: "/static/textures/particle.png",
            radius: 0.005,
        },
    },
    PLANETS_DEFAULT_OPTIONS: [
        {
            ...Spacekit.SpaceObjectPresets.MERCURY,
            name: "Mercury",
            textureUrl: "/static/textures/detailed/mercury.jpg",
            radius: 0.0024394,
            rotation: { enable: true, period: 58.646, lambdaDeg: 252.2509 },
        },
        {
            ...Spacekit.SpaceObjectPresets.VENUS,
            name: "Venus",
            textureUrl: "/static/textures/detailed/venus.jpg",
            radius: 0.0060518,
            rotation: {
                enable: true,
                period: -243.018,
                lambdaDeg: 181.9798,
            },
        },
        {
            ...Spacekit.SpaceObjectPresets.MARS,
            name: "Mars",
            textureUrl: "/static/textures/detailed/mars.jpg",
            radius: 0.0033895,
            rotation: { enable: true, period: 1.026, lambdaDeg: 355.4329 },
        },
        {
            ...Spacekit.SpaceObjectPresets.JUPITER,
            name: "Jupiter",
            textureUrl: "/static/textures/detailed/jupiter.jpg",
            radius: 0.069911,
            rotation: { enable: true, period: 0.41354, lambdaDeg: 34.3515 },
        },
        {
            ...Spacekit.SpaceObjectPresets.SATURN,
            name: "Saturn",
            textureUrl: "/static/textures/detailed/saturn.jpg",
            radius: 0.058232,
            rotation: { enable: true, period: 0.444, lambdaDeg: 50.0774 },
        },
        {
            ...Spacekit.SpaceObjectPresets.URANUS,
            name: "Uranus",
            textureUrl: "/static/textures/detailed/uranus.jpg",
            radius: 0.025362,
            rotation: { enable: true, period: -0.718, lambdaDeg: 314.055 },
        },
        {
            ...Spacekit.SpaceObjectPresets.NEPTUNE,
            name: "Neptune",
            textureUrl: "/static/textures/detailed/neptune.jpg",
            radius: 0.024622,
            rotation: { enable: true, period: 0.671, lambdaDeg: 304.3486 },
        },
    ],
    TRANSFORMED_PLANETS_NAMES: [
        "mercury_transformed",
        "venus_transformed",
        "mars_transformed",
        "jupiter_transformed",
        "saturn_transformed",
        "uranus_transformed",
        "neptune_transformed",
    ],
    ORBIT_RANGE_COLOR: "#ff6400",

    async createSynodicOrbit(objectData, ephemData, designator) {
        const {
            isObjectSelected,
            isObjectVisible,
        } = SynodicCalculationService.getSynodicObjectVisibilityInfo(
            designator
        );
        const spaceObjectOrbitData = SpacekitService.getSpaceObjectData(
            ephemData
        );
        if (!spaceObjectOrbitData) {
            return false;
        }
        const isPerturbedOrbit = SynodicCalculationService.getPerturbedSynodicOrbitList().includes(
            designator
        );
        if (isObjectSelected) {
            SynodicCalculationService.createOrbitRange(objectData, spaceObjectOrbitData);
            SynodicCalculationService.createDetectionPolarObjects(objectData);
        }
        if (isObjectVisible) {
            SpacekitService.handleSynodicOrbitHiglight();
            isObjectSelected && SynodicCalculationService.setActiveObject(designator);
            return true;
        }
        if (!isPerturbedOrbit) {
            SynodicCalculationService.createSynodicOrbitObject(ephemData, objectData, spaceObjectOrbitData, designator);
            return true;
        }
        if (isPerturbedOrbit) {
            return await SynodicCalculationService.createSynodicPerturbedOrbitObject(ephemData, objectData, designator);
        }
    },

    createOrbitRange(objectData, spaceObjectData) {
        SpacekitService.removeOrbitRangeObject();
        const { perihelionDistance, aphelionDistance } = objectData;
        const { minZValue, maxZValue } = SynodicCalculationService.getOrbitRangeLimitValues(spaceObjectData);
        const perihelionCircumferenceData = SynodicCalculationService.calculateOrbitRangeEdge(
            perihelionDistance,
            minZValue,
            maxZValue
        );
        const aphelionCircumferenceData = SynodicCalculationService.calculateOrbitRangeEdge(
            aphelionDistance,
            minZValue,
            maxZValue
        );
        SpacekitService.addOrbitRange(perihelionCircumferenceData, aphelionCircumferenceData);
    },

    getOrbitRangeLimitValues(spaceObjectData) {
        const objectOrbitZPoints = spaceObjectData.getOrbit().orbitPoints.map(({ z }) => z);
        return {
            minZValue: Math.min(...objectOrbitZPoints),
            maxZValue: Math.max(...objectOrbitZPoints),
        };
    },

    calculateOrbitRangeEdge(distance, minZ, maxZ) {
        return [
            [distance, minZ],
            [distance, maxZ],
        ];
    },

    createDetectionPolarObjects(objectData) {
        const detectionPolarCoordinates = [];
        for (let i = 0; i <= 179; i += SynodicCalculationService.DETECTION_POLAR_STEP_ANGLE) {
            const pointCoordinates = SynodicCalculationService.calculatePolarPlotCoordinates(i, objectData);
            detectionPolarCoordinates.push(pointCoordinates);
        }
        SynodicCalculationService.setDetectionPolarPoints(detectionPolarCoordinates);
        SpacekitService.calculateDetectionPolarObject();
    },

    calculatePolarPlotCoordinates(phaseAngleDeg, objectData) {
        const { absoluteMagnitude, magnitudeLimit, slopeParameter } = objectData;

        const phaseAngleTangens = Math.tan((phaseAngleDeg * Math.PI) / 180 / 2);
        const phi1 = Math.exp(-3.33 * Math.pow(phaseAngleTangens, 0.63));
        const phi2 = Math.exp(-1.87 * Math.pow(phaseAngleTangens, 1.22));
        const apparentMagnitude =
            absoluteMagnitude - 2.5 * Math.log10((1 - slopeParameter) * phi1 + slopeParameter * phi2);
        const productDistance = Math.pow(10, (parseFloat(magnitudeLimit) - apparentMagnitude) / 5);
        const k2Param = 1 + 2 * productDistance * Math.cos((phaseAngleDeg * Math.PI) / 180);
        const d1Param =
            k2Param > 2 * productDistance
                ? Math.sqrt((k2Param + Math.sqrt(k2Param ** 2 - 4 * productDistance ** 2)) / 2)
                : 0;
        const r1Param = k2Param > 2 * productDistance ? productDistance / d1Param : 0;
        const del1Param =
            k2Param > 2 * productDistance
                ? (Math.asin(r1Param * Math.sin((phaseAngleDeg * Math.PI) / 180)) * 180) / Math.PI
                : 0;
        const beta1Param = k2Param > 2 * productDistance ? phaseAngleDeg + del1Param : 0;
        const y1Coord = k2Param > 2 * productDistance ? 1 + r1Param * Math.cos((beta1Param * Math.PI) / 180) : null;
        const x1Coord = k2Param > 2 * productDistance ? r1Param * Math.sin((beta1Param * Math.PI) / 180) : null;

        return [x1Coord, y1Coord];
    },

    setActiveObject(designator) {
        const spaceObjects = window["vt"].spaceObjects;
        const isPerturbed = SynodicCalculationService.getPerturbedSynodicOrbitList().includes(designator);
        const spaceObjectId = SynodicCalculationService.getTransformedSpaceObjectId(designator, isPerturbed);
        const spaceObject = UtilsService.findItemInObjectList("_id", spaceObjectId, spaceObjects);
        SynodicCalculationService.setSynodicActiveObject(spaceObject);
    },

    createSynodicOrbitObject(ephemData, objectData, spaceObjectData, designator) {
        const earthObject = SynodicCalculationService.getEarthObjectData();
        const { startDateJulian, endDateJulian } = SynodicCalculationService.getCurrentJulianTimeframe();
        const { absoluteMagnitude, slopeParameter } = objectData;
        const transformedOrbitPointsData = [];
        for (
            let dayJulian = startDateJulian;
            dayJulian <= endDateJulian;
            dayJulian += SynodicCalculationService.PROPAGATION_TIME_STEP_DAY
        ) {
            transformedOrbitPointsData.push({
                ...SynodicCalculationService.calculateSynodicOrbitPointData(
                    dayJulian,
                    earthObject,
                    spaceObjectData,
                    absoluteMagnitude,
                    slopeParameter
                ),
                epoch: dayJulian,
                date: UtilsService.julianToDatestring(dayJulian),
            });
        }
        SynodicChartService.updateSynodicOrbitObjectChartsData(
            designator,
            startDateJulian,
            endDateJulian,
            transformedOrbitPointsData.map(
                // eslint-disable-next-line
                ({ epoch, xa, ya, za, ...pointChartData }) => pointChartData
            )
        );
        SynodicCalculationService.addSynodicOrbit(transformedOrbitPointsData, ephemData, designator);
    },

    calculateSynodicOrbitPointData(dayJulian, earthObject, spaceObjectData, absoluteMagnitude, slopeParameter) {
        const earthNormalPosition = earthObject.getPosition(dayJulian);
        const [xNormalEarth, yNormalEarth] = earthNormalPosition;
        const {
            x: xTransformedEarth,
            y: yTransformedEarth,
            z: zTransformedEarth,
        } = SynodicCalculationService.calculateTransfomredEarthPointData(earthObject, dayJulian);
        const [xNormalObject, yNormalObject, zNormalObject] = spaceObjectData.getPosition(dayJulian);
        const dx = xNormalObject - xNormalEarth;
        const dy = yNormalObject - yNormalEarth;
        const modulo = +VisualisationService.distanceToCenter(earthNormalPosition, 10);
        const u1x = xNormalEarth / modulo;
        const u1y = yNormalEarth / modulo;
        const u2x = -xTransformedEarth * u1y;
        const u2y = u1x;
        const xTransformedObject = dx * u1x + dy * u1y + xTransformedEarth;
        const yTransformedObject = dx * u2x + dy * u2y;
        const transformedSpaceObjectSunDistance = +VisualisationService.distanceToCenter(
            [xTransformedObject, yTransformedObject, zNormalObject],
            10
        );
        const transformedSpaceObjectTransformedEarthDistance = +VisualisationService.distanceToBody(
            [xTransformedObject, yTransformedObject, zNormalObject],
            [xTransformedEarth, yTransformedEarth, zTransformedEarth],
            10
        );

        const argument =
            (Math.pow(transformedSpaceObjectSunDistance, 2) +
                Math.pow(transformedSpaceObjectTransformedEarthDistance, 2) -
                Math.pow(xTransformedEarth, 2)) /
            (2 * transformedSpaceObjectSunDistance * transformedSpaceObjectTransformedEarthDistance);

        const transformedSpaceObjectPhaseAngle = Math.acos(argument) * (180 / Math.PI);

        const transformedSpaceObjectPhaseAngleTangens = Math.tan(
            (transformedSpaceObjectPhaseAngle * Math.PI) / 180 / 2
        );
        const transformedSpaceObjectPhi1 = Math.exp(-3.33 * Math.pow(transformedSpaceObjectPhaseAngleTangens, 0.63));
        const transformedSpaceObjectPhi2 = Math.exp(-1.87 * Math.pow(transformedSpaceObjectPhaseAngleTangens, 1.22));
        const apparentMag =
            absoluteMagnitude -
            2.5 *
                Math.log10(
                    (1 - slopeParameter) * transformedSpaceObjectPhi1 + slopeParameter * transformedSpaceObjectPhi2
                ) +
            5 * Math.log10(transformedSpaceObjectSunDistance * transformedSpaceObjectTransformedEarthDistance);
        return {
            xa: xTransformedObject,
            ya: yTransformedObject,
            za: zNormalObject,
            earthDistance: transformedSpaceObjectTransformedEarthDistance,
            phaseAngle: transformedSpaceObjectPhaseAngle,
            apparentMag,
        };
    },

    addSynodicOrbit(transformedOrbitPointsData, ephemData, designator, isPerturbed = false) {
        const vt = window["vt"];
        const objectsSettings = VisualisationService.getSettings().objectsSettings;
        // const isEarthOnScale = VisualisationService.getSettings().objectsSettings.earthOnScale.value;
        // const asteroidScaleFactor = isEarthOnScale ? SynodicCalculationService.EARTH_ON_SCALE_ASTEROID_FACTOR : 1;
        const impactDate = SynodicCalculationService.checkImpactDate(designator);
        const { synodicOrbitPoints, synodicOrbitYears } = SynodicCalculationService.getSynodicOrbitPointsInfo();
        const ephemTransformedSpaceObject =
            SynodicCalculationService.createEphemeridesTable(transformedOrbitPointsData);
        const transformedSpaceObjectOptions = {
            ...SpacekitService.spaceObjectOptions(),
            ...{
                name: ephemData.objectName || ephemData.designator,
                diam: ephemData.diam || "-",
                mag: ephemData.mag,
                labelLayer: 2,
                ephemTable: ephemTransformedSpaceObject,
                orbitPathSettings: {
                    numberSamplePoints: 25 * synodicOrbitPoints,
                    leadDurationYears: synodicOrbitYears,
                    trailDurationYears: synodicOrbitYears,
                },
            },
        };
        const spaceObjectId = SynodicCalculationService.getTransformedSpaceObjectId(
            designator,
            isPerturbed
        );
        const transformedSpaceObject = vt.createSphere(
            spaceObjectId,
            transformedSpaceObjectOptions
        );
        // transformedSpaceObject._object3js.scale.setScalar(asteroidScaleFactor);
        let isObjectInvisible = false;
        if (isPerturbed && impactDate) {
            const simulationDate = moment.utc(TimeFrameService.getSimulationTime()).format("YYYY-MM-DD");
            const simulationTimestamp = new Date(simulationDate).getTime();
            const impactTimestamp = new Date(impactDate).getTime();
            isObjectInvisible = simulationTimestamp > impactTimestamp;
        }
        isObjectInvisible && (transformedSpaceObject._object3js.visible = false);
        vt.spaceObjects.push(transformedSpaceObject);
        SpacekitService.handleSynodicOrbitHiglight();
        !isObjectInvisible &&
            HUDService.createLabel(
                transformedSpaceObject,
                UtilsService.hexColor(objectsSettings.objectNames.color),
                objectsSettings.objectNamesSize.value
            );

        if (designator === VisualisationService.getSelectedSynodicObjectName()) {
            SynodicCalculationService.setSynodicActiveObject(transformedSpaceObject);
        }

        if (isPerturbed && impactDate) {
            PopupService.show({
                component: "PopupInfo",
                type: "warning",
                message: Keys.t.trajectoryImpact
                    .replace("{objectName}", designator)
                    .replace("{impactDate}", impactDate),
            });
        }

        const settings = VisualisationService.getSettings();
        const asteroidScaleFactor = settings && settings.objectsSettings.asteroidsSizeFactor.value;
        SpacekitService.updateAsteroidsSizeFactor(asteroidScaleFactor, 'sovt');
        SpacekitService.toggleLightEffect(false);
    },

    async createSynodicPerturbedOrbitObject(ephemData, objectData, designator) {
        const { startDateJulian, endDateJulian } = SynodicCalculationService.getCurrentJulianTimeframe();
        const { absoluteMagnitude, slopeParameter } = objectData;
        const perturbedPointsData = await SynodicCalculationService.getPerturbedSynodicOrbitParameters(designator);
        const hasPoints = SynodicCalculationService.hasPerturbedPoints(perturbedPointsData);
        if (!hasPoints) {
            return false;
        }
        const transformedOrbitPointsData = SynodicCalculationService.getTransformedPerturbedPointsData(
            perturbedPointsData,
            absoluteMagnitude,
            slopeParameter,
            startDateJulian
        );
        SynodicChartService.updateSynodicOrbitObjectChartsData(
            designator,
            startDateJulian,
            endDateJulian,
            transformedOrbitPointsData.map(
                // eslint-disable-next-line
                ({ epoch, xa, ya, za, ...pointChartData }) => pointChartData
            ),
            true
        );
        SynodicCalculationService.addSynodicOrbit(transformedOrbitPointsData, ephemData, designator, true);
        return true;
    },

    getSynodicOrbitPointsInfo() {
        const { startDateJulian, endDateJulian } = SynodicCalculationService.getCurrentJulianTimeframe();
        const synodicOrbitPoints =
            endDateJulian - startDateJulian / SynodicCalculationService.PROPAGATION_TIME_STEP_DAY;
        const synodicOrbitYears = (endDateJulian - startDateJulian) / SynodicCalculationService.EARTH_PERIOD;
        return { synodicOrbitPoints, synodicOrbitYears };
    },

    recalculateVisibleSynodicOrbits() {
        const visibleRealSynodicObjectsList = UtilsService.deepCopy(
            SynodicCalculationService.getVisibleRealSynodicObjectsList()
        );
        if (!visibleRealSynodicObjectsList.length) {
            return;
        }
        const perturbedOrbitList = SynodicCalculationService.getPerturbedSynodicOrbitList();
        const asteroidsList = ObjectsService.getFinalAsteroidsList();
        const syntheticObjectsList = SyntheticObjectsService.getSyntheticObjectsList();
        const removedObjectsList = [];
        visibleRealSynodicObjectsList.forEach(async (objectDesignator) => {
            const isPerturbedOrbit = perturbedOrbitList.includes(objectDesignator);
            SynodicCalculationService.removeExistingSpaceObject(objectDesignator, isPerturbedOrbit);
            if (
                !asteroidsList.some(
                    (asteroid) => (asteroid.number ? asteroid.number : asteroid.name).toString() === objectDesignator
                ) &&
                !syntheticObjectsList.some((object) => object.designator === objectDesignator)
            ) {
                removedObjectsList.push(objectDesignator);
                return;
            }
            const { objectParameters, objectEphemData } =
                SynodicCalculationService.getCalculatedInvisibleObjectParameters(objectDesignator);
            const spaceObjectOrbitData = SpacekitService.getSpaceObjectData(objectEphemData);
            if (!spaceObjectOrbitData) {
                return;
            }
            const isDetectionPolarObjectAdded = SpacekitService.getDetectionPolarObjects().length !== 0;
            const {
                isObjectSelected,
            } = SynodicCalculationService.getSynodicObjectVisibilityInfo(
                objectDesignator
            );
            if (!isDetectionPolarObjectAdded && isObjectSelected) {
                SynodicCalculationService.createOrbitRange(
                    objectParameters,
                    spaceObjectOrbitData
                );
                SynodicCalculationService.createDetectionPolarObjects(objectParameters);
            }
            !isPerturbedOrbit &&
                SynodicCalculationService.createSynodicOrbitObject(
                    objectEphemData,
                    objectParameters,
                    spaceObjectOrbitData,
                    objectDesignator
                );
            if (isPerturbedOrbit) {
                const isPerturbedOrbitAvailable = await SynodicCalculationService.createSynodicPerturbedOrbitObject(
                    objectEphemData,
                    objectParameters,
                    objectDesignator
                );
                !isPerturbedOrbitAvailable && SynodicCalculationService.handlePerturbedUnavailable(objectDesignator);
            }
            SynodicCalculationService.checkIsFocusOnObject(objectDesignator);
        });
        if (!removedObjectsList.length) {
            return;
        }
        const calculatedObjectList = UtilsService.deepCopy(
            SynodicCalculationService.getCalculatedRealSynodicObjectsList()
        );
        removedObjectsList.forEach((objectDesignator) => {
            const calculatedObjectIndex = UtilsService.findItemIndexInObjectList(
                "designator",
                objectDesignator,
                calculatedObjectList
            );
            calculatedObjectIndex !== -1 && calculatedObjectList.splice(calculatedObjectIndex, 1);
            SynodicCalculationService.removePerturbedObjectOrbit(objectDesignator);
            const { isObjectSelected } = SynodicCalculationService.getSynodicObjectVisibilityInfo(objectDesignator);
            isObjectSelected && SynodicCalculationService.deselectVisibleSpaceObject();
            SynodicChartService.removeSynodicOrbitObjectChartData(objectDesignator);
        });
        SynodicCalculationService.setCalculatedRealSynodicObjectsList(calculatedObjectList);
        const filteredVisibleRealSynodicObjectsList = visibleRealSynodicObjectsList.filter(
            (designator) => !removedObjectsList.includes(designator)
        );
        SynodicCalculationService.setVisibleRealSynodicObjectsList(filteredVisibleRealSynodicObjectsList);
    },

    handlePerturbedUnavailable(designator) {
        SynodicCalculationService.showNoPerturbedInfo(designator);
        SynodicChartService.removeSynodicOrbitObjectChartData(designator, false);
        const perturbedOrbitList = UtilsService.deepCopy(SynodicCalculationService.getPerturbedSynodicOrbitList());
        const objectIndex = perturbedOrbitList.indexOf(designator);
        objectIndex !== -1 ? perturbedOrbitList.splice(objectIndex, 1) : null;
        SynodicCalculationService.setPerturbedSynodicOrbitList(
            perturbedOrbitList
        );
        const {
            objectParameters,
            objectEphemData,
        } = SynodicCalculationService.getCalculatedInvisibleObjectParameters(
            designator
        );
        const spaceObjectOrbitData = SpacekitService.getSpaceObjectData(
            objectEphemData
        );
        if (!spaceObjectOrbitData) {
            return;
        }
        SynodicCalculationService.createSynodicOrbitObject(
            objectEphemData,
            objectParameters,
            spaceObjectOrbitData,
            designator
        );
    },

    getTransformedSpaceObjectId(designator, isPerturbed = false) {
        return `${designator}${
            isPerturbed
                ? SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX_PERTURBED
                : SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX
        }`;
    },

    handleObjectVisibility(designator) {
        const { isObjectVisible } = SynodicCalculationService.getSynodicObjectVisibilityInfo(designator);
        isObjectVisible
            ? SynodicCalculationService.removeVisibleCalculatedObject(designator)
            : SynodicCalculationService.showCalculatedInvisibleObject(designator);
    },

    removeVisibleCalculatedObject(designator) {
        const { isObjectSelected, objectIndexInVisibleList } =
            SynodicCalculationService.getSynodicObjectVisibilityInfo(designator);
        const isPerturbed = SynodicCalculationService.getPerturbedSynodicOrbitList().includes(designator);
        const visibleRealSynodicObjectsList = SynodicCalculationService.getVisibleRealSynodicObjectsList();
        visibleRealSynodicObjectsList.splice(objectIndexInVisibleList, 1);
        SynodicCalculationService.setVisibleRealSynodicObjectsList(visibleRealSynodicObjectsList);
        SynodicCalculationService.removeExistingSpaceObject(designator, isPerturbed);
        SynodicChartService.removeSynodicOrbitObjectChartData(designator);
        isObjectSelected && SynodicCalculationService.deselectVisibleSpaceObject();
    },

    removePerturbedObjectOrbit(designator) {
        const perturbedOrbitsList = SynodicCalculationService.getPerturbedSynodicOrbitList();
        const objectIndex = perturbedOrbitsList.indexOf(designator);
        if (objectIndex === -1) {
            return;
        }
        perturbedOrbitsList.splice(objectIndex, 1);
        SynodicCalculationService.setPerturbedSynodicOrbitList(perturbedOrbitsList);
    },

    async showCalculatedInvisibleObject(designator) {
        const visibleRealSynodicObjectsList = SynodicCalculationService.getVisibleRealSynodicObjectsList();
        const { objectParameters, objectEphemData } =
            SynodicCalculationService.getCalculatedInvisibleObjectParameters(designator);
        const isOrbitAvailable = await SynodicCalculationService.createSynodicOrbit(
            objectParameters,
            objectEphemData,
            designator
        );
        if (!isOrbitAvailable) {
            SynodicCalculationService.handlePerturbedUnavailable(designator);
        }
        visibleRealSynodicObjectsList.push(designator);
        SynodicCalculationService.setVisibleRealSynodicObjectsList(visibleRealSynodicObjectsList);
    },

    getCalculatedInvisibleObjectParameters(designator) {
        const invisibleCalculatedObject = UtilsService.findItemInObjectList(
            "designator",
            designator,
            SynodicCalculationService.getCalculatedRealSynodicObjectsList()
        );
        const objectParameters = {};
        const objectEphemData = invisibleCalculatedObject.objectData;
        const parametersMap = SynodicCalculationService.getObjectParamtersMap();
        Object.keys(parametersMap).forEach((key) => (objectParameters[parametersMap[key]] = objectEphemData[key]));
        objectParameters["magnitudeLimit"] = invisibleCalculatedObject.magnitudeLimit;
        return { objectParameters, objectEphemData };
    },

    switchOrbitToKeplerian(designator) {
        SynodicCalculationService.removeExistingSpaceObject(designator, true);
        const {
            objectParameters,
            objectEphemData,
        } = SynodicCalculationService.getCalculatedInvisibleObjectParameters(
            designator
        );
        const spaceObjectOrbitData = SpacekitService.getSpaceObjectData(
            objectEphemData
        );
        if (!spaceObjectOrbitData) {
            return;
        }
        SynodicCalculationService.createSynodicOrbitObject(
            objectEphemData,
            objectParameters,
            spaceObjectOrbitData,
            designator
        );
    },

    async switchOrbitToPerturbed(designator) {
        const perturbedPointsData = await SynodicCalculationService.getPerturbedSynodicOrbitParameters(designator);
        const hasPoints = SynodicCalculationService.hasPerturbedPoints(perturbedPointsData);
        if (!hasPoints) {
            return false;
        }
        SynodicCalculationService.removeExistingSpaceObject(designator);
        SynodicCalculationService.createSynodicPerturbedOrbit(designator, perturbedPointsData);
        return true;
    },

    createSynodicPerturbedOrbit(designator, perturbedPointsData) {
        const { startDateJulian, endDateJulian } = SynodicCalculationService.getCurrentJulianTimeframe();
        const { objectParameters, objectEphemData } =
            SynodicCalculationService.getCalculatedInvisibleObjectParameters(designator);
        const { absoluteMagnitude, slopeParameter } = objectParameters;
        const transformedOrbitPointsData = SynodicCalculationService.getTransformedPerturbedPointsData(
            perturbedPointsData,
            absoluteMagnitude,
            slopeParameter,
            startDateJulian
        );
        SynodicChartService.updateSynodicOrbitObjectChartsData(
            designator,
            startDateJulian,
            endDateJulian,
            transformedOrbitPointsData.map(
                // eslint-disable-next-line
                ({ epoch, xa, ya, za, ...pointChartData }) => pointChartData
            ),
            true
        );
        SynodicCalculationService.addSynodicOrbit(transformedOrbitPointsData, objectEphemData, designator, true);
    },

    getTransformedPerturbedPointsData(perturbedPointsData, absoluteMagnitude, slopeParameter, startDateJulian) {
        const positionData = Object.values(perturbedPointsData);
        return positionData.map((positionInfo, index) => {
            const { asteroid: asteroidPositionInfo, earth: earthPositionInfo } = positionInfo;
            const asteroidPosition = [asteroidPositionInfo[0], asteroidPositionInfo[1], asteroidPositionInfo[2]];
            const earthPosition = [earthPositionInfo[0], earthPositionInfo[1], earthPositionInfo[2]];
            return {
                xa: asteroidPosition[0],
                ya: asteroidPosition[1],
                za: asteroidPosition[2],
                epoch: startDateJulian + index,
                date: UtilsService.julianToDatestring(startDateJulian + index),
                ...SynodicCalculationService.calculateSynodicPerturbedOrbitPointData(
                    asteroidPosition,
                    earthPosition,
                    absoluteMagnitude,
                    slopeParameter
                ),
            };
        });
    },

    removeExistingSpaceObject(designator, isPerturbed = false) {
        const vt = window["vt"];
        const spaceObjectId = SynodicCalculationService.getTransformedSpaceObjectId(designator, isPerturbed);
        const spaceObject = UtilsService.findItemInObjectList("_id", spaceObjectId, vt.spaceObjects);
        if (spaceObject) {
            HUDService.removeLabel(spaceObject);
            vt.removeObject(spaceObject);
            const indexToRemove = vt.spaceObjects.indexOf(spaceObject);
            vt.spaceObjects.splice(indexToRemove, 1);
        }
    },

    calculateSynodicPerturbedOrbitPointData(objectPosition, earthPosition, absoluteMagnitude, slopeParameter) {
        const transformedSpaceObjectSunDistance = +VisualisationService.distanceToCenter(objectPosition, 10);
        const transformedSpaceObjectTransformedEarthDistance = +VisualisationService.distanceToBody(
            objectPosition,
            earthPosition,
            10
        );
        let argument =
            (Math.pow(transformedSpaceObjectSunDistance, 2) +
                Math.pow(transformedSpaceObjectTransformedEarthDistance, 2) -
                Math.pow(earthPosition[0], 2)) /
            (2 * transformedSpaceObjectSunDistance * transformedSpaceObjectTransformedEarthDistance);
        const transformedSpaceObjectPhaseAngle = Math.acos(argument) * (180 / Math.PI);
        const transformedSpaceObjectPhaseAngleTangens = Math.tan(
            (transformedSpaceObjectPhaseAngle * Math.PI) / 180 / 2
        );
        const transformedSpaceObjectPhi1 = Math.exp(-3.33 * Math.pow(transformedSpaceObjectPhaseAngleTangens, 0.63));
        const transformedSpaceObjectPhi2 = Math.exp(-1.87 * Math.pow(transformedSpaceObjectPhaseAngleTangens, 1.22));
        const apparentMag =
            absoluteMagnitude -
            2.5 *
                Math.log10(
                    (1 - slopeParameter) * transformedSpaceObjectPhi1 + slopeParameter * transformedSpaceObjectPhi2
                ) +
            5 * Math.log10(transformedSpaceObjectSunDistance * transformedSpaceObjectTransformedEarthDistance);
        return {
            earthDistance: transformedSpaceObjectTransformedEarthDistance,
            phaseAngle: transformedSpaceObjectPhaseAngle,
            apparentMag,
        };
    },

    deselectVisibleSpaceObject() {
        VisualisationService.setSelectedSynodicObjectName("");
        SpacekitService.removeDetectionPolarObjects();
        SpacekitService.removeOrbitRangeObject();
    },

    toggleSynodicPlanets(isVisible) {
        isVisible
            ? SynodicCalculationService.createTransformedPlanets()
            : SynodicCalculationService.removeExistingTransformedPlanetsObject();
    },

    recalculateTransformedPlanets() {
        const settings = VisualisationService.getSettings();
        const isPlanetsVisible = settings && settings.objectsSettings.synodicPlanets.value;
        if (!isPlanetsVisible) {
            return;
        }
        SynodicCalculationService.removeExistingTransformedPlanetsObject();
        SynodicCalculationService.createTransformedPlanets();
    },

    createTransformedPlanets() {
        const earthObject = SynodicCalculationService.getEarthObjectData();
        const { startDateJulian, endDateJulian } = SynodicCalculationService.getCurrentJulianTimeframe();
        const { synodicOrbitPoints, synodicOrbitYears } = SynodicCalculationService.getSynodicOrbitPointsInfo();
        const orbitPathSettings = {
            numberSamplePoints: 2 * synodicOrbitPoints,
            leadDurationYears: synodicOrbitYears,
            trailDurationYears: synodicOrbitYears,
        };
        SynodicCalculationService.PLANETS_DEFAULT_OPTIONS.forEach((planetOptions, index) => {
            const planetName = SynodicCalculationService.TRANSFORMED_PLANETS_NAMES[index];
            const extendedPlanetOptions = {
                ...planetOptions,
                orbitPathSettings,
            };
            const planetData = SynodicCalculationService.getPlanetData(planetName, planetOptions);
            const transformedOrbitPointsData = [];
            for (
                let dayJulian = startDateJulian;
                dayJulian <= endDateJulian;
                dayJulian += SynodicCalculationService.PROPAGATION_TIME_STEP_DAY
            ) {
                transformedOrbitPointsData.push({
                    ...SynodicCalculationService.calculateSynodicOrbitplanetPointData(
                        dayJulian,
                        earthObject,
                        planetData
                    ),
                    epoch: dayJulian,
                });
            }
            const planetEphemeridesTable = SynodicCalculationService.createEphemeridesTable(transformedOrbitPointsData);
            SynodicCalculationService.addTransformedPlanetObject(
                planetName,
                extendedPlanetOptions,
                planetEphemeridesTable
            );
        });
        const objectsSettings = VisualisationService.getSettings().objectsSettings;
        const isPlanetLabelVisible = objectsSettings.synodicPlanetsNames.value;
        const isPlanetOrbitVisible = objectsSettings.synodicPlanetsOrbits.value;
        const orbitColor = objectsSettings.synodicPlanetsOrbits.color;
        SynodicCalculationService.toggleSynodicPlanetsNames(isPlanetLabelVisible);
        SynodicCalculationService.toggleSynodicPlanetsOrbits(isPlanetOrbitVisible);
        SynodicCalculationService.updateSynodicPlanetsOrbitsColor(orbitColor);
    },

    removeExistingTransformedPlanetsObject() {
        const vt = window["vt"];
        SynodicCalculationService.TRANSFORMED_PLANETS_NAMES.forEach((planetName) => {
            const transformedPlanetObject = UtilsService.findItemInObjectList("_id", planetName, vt.planets);
            if (transformedPlanetObject) {
                HUDService.removeLabel(transformedPlanetObject);
                vt.removeObject(transformedPlanetObject);
                const indexToRemove = vt.planets.indexOf(transformedPlanetObject);
                vt.planets.splice(indexToRemove, 1);
            }
        });
    },

    calculateSynodicOrbitplanetPointData(dayJulian, earthObject, planetData) {
        const earthNormalPosition = earthObject.getPosition(dayJulian);
        const [xNormalEarth, yNormalEarth] = earthNormalPosition;
        const [xNormalPlanet, yNormalPlanet, zNormalPlanet] = planetData.getPosition(dayJulian);
        const dx = xNormalPlanet - xNormalEarth;
        const dy = yNormalPlanet - yNormalEarth;
        const modulo = +VisualisationService.distanceToCenter(earthNormalPosition, 10);
        const u1x = xNormalEarth / modulo;
        const u1y = yNormalEarth / modulo;
        const u2x = -1 * u1y;
        const u2y = u1x;
        const xTransformedPlanet = dx * u1x + dy * u1y + 1;
        const yTransformedPlanet = dx * u2x + dy * u2y;
        return {
            xa: xTransformedPlanet,
            ya: yTransformedPlanet,
            za: zNormalPlanet,
        };
    },

    getPlanetData(planetName, planetOptions) {
        const vt = window["vt"];
        const planet = vt.createSphere(planetName, planetOptions);
        vt.removeObject(planet);
        return planet;
    },

    addTransformedPlanetObject(planetName, planetOptions, planetEphemeridesTable) {
        const vt = window["vt"];
        const transformedPlanetObjectOptions = {
            ...planetOptions,
            ephemTable: planetEphemeridesTable,
            labelLayer: 0,
        };
        const transformedPlanetObject = vt.createSphere(planetName, transformedPlanetObjectOptions);
        vt.planets.push(transformedPlanetObject);
        const objectsSettings = VisualisationService.getSettings().objectsSettings;
        HUDService.createLabel(
            transformedPlanetObject,
            UtilsService.hexColor(objectsSettings.synodicPlanetsNames.color),
            objectsSettings.synodicPlanetsNamesSize.value
        );
    },

    toggleSynodicPlanetsNames(isVisible) {
        const vt = window['vt'];
        isVisible ? vt.cameraOrtho.layers.enable(0) : vt.cameraOrtho.layers.disable(0);
    },

    updateSynodicPlanetLabel(labelOption, labelOptionValue) {
        const planets = window['vt'].planets;
        const excludedPlanetsList = SynodicCalculationService.getExludedSynodicPlanets();
        const filteredPlanets = planets.filter(planet => !excludedPlanetsList.includes(planet._id));
        filteredPlanets.forEach(planet => {
            if (!planet._label3d) {
                return;
            }
            labelOption === 'fontSize' && (planet._label3d[0].fontSize = labelOptionValue);
            labelOption === 'color' && (planet._label3d[0].color = labelOptionValue)
        });
    },

    toggleSynodicPlanetsOrbits(isVisible) {
        const planets = window['vt'].planets;
        const excludedPlanetsList = SynodicCalculationService.getExludedSynodicPlanets();
        const filteredPlanets = planets.filter(planet => !excludedPlanetsList.includes(planet._id));
        filteredPlanets.forEach(planet => planet.get3jsObjects()[2] && (planet.get3jsObjects()[2].visible = isVisible));
    },

    updateSynodicPlanetsOrbitsColor(color) {
        const planets = window['vt'].planets;
        const excludedPlanetsList = SynodicCalculationService.getExludedSynodicPlanets();
        const filteredPlanets = planets.filter(planet => !excludedPlanetsList.includes(planet._id));
        const orbitColor = new THREE.Color(UtilsService.hexColor(color));
        filteredPlanets.forEach(planet => planet.getOrbit().setHexColor(orbitColor));
    },

    getExludedSynodicPlanets() {
        return [SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME, SynodicCalculationService.EARTH_FAKE_MOON_NAME, SynodicCalculationService.EARTH_PLACEHOLDER_NAME];
    },

    createTransformedEarthObject() {
        SynodicCalculationService.removeExistingTransformedEarthObject();
        SynodicCalculationService.removeExistingEarthRotationHelpers();
        const earthObject = SynodicCalculationService.getEarthObjectData();
        const { startDateJulian, endDateJulian } = SynodicCalculationService.getCurrentJulianTimeframe();
        const transformedEarthPoints = [];
        for (
            let dayJulian = startDateJulian;
            dayJulian <= endDateJulian;
            dayJulian += SynodicCalculationService.PROPAGATION_TIME_STEP_DAY
        ) {
            const { x, y, z } = SynodicCalculationService.calculateTransfomredEarthPointData(earthObject, dayJulian);
            transformedEarthPoints.push({
                epoch: dayJulian,
                xa: x,
                ya: y,
                za: z,
            });
        }
        const earthEphemeridesTable = SynodicCalculationService.createEphemeridesTable(transformedEarthPoints);
        const fakeEarthEphemeridesTable = SynodicCalculationService.createEphemeridesTable(
            transformedEarthPoints.map((data) => ({ ...data, za: 0.5 }))
        );
        SynodicCalculationService.addTransformedEarthObject(earthEphemeridesTable, fakeEarthEphemeridesTable);
        SpacekitService.toggleLightEffect(false);
        SynodicCalculationService.checkIsFocusOnObject(SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME);
    },

    getEarthObjectData() {
        const vt = window["vt"];
        const earthObject = vt.createSphere("earth", SynodicCalculationService.EARTH_OBJECT_DEFAULT_OPTIONS);
        vt.removeObject(earthObject);
        return earthObject;
    },

    removeExistingTransformedEarthObject() {
        const vt = window["vt"];
        const transformedEarthObject = UtilsService.findItemInObjectList(
            "_id",
            SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME,
            vt.planets
        );
        if (transformedEarthObject) {
            HUDService.removeLabel(transformedEarthObject);
            vt.removeObject(transformedEarthObject);
            const indexToRemove = vt.planets.indexOf(transformedEarthObject);
            vt.planets.splice(indexToRemove, 1);
        }
    },

    removeExistingEarthRotationHelpers() {
        const vt = window["vt"];
        const earthPlaceholderObject = UtilsService.findItemInObjectList(
            "_id",
            SynodicCalculationService.EARTH_PLACEHOLDER_NAME,
            vt.planets
        );
        const earthFakeMoonObject = UtilsService.findItemInObjectList(
            "_id",
            SynodicCalculationService.EARTH_FAKE_MOON_NAME,
            vt.planets
        );
        const helperObjects = [earthPlaceholderObject, earthFakeMoonObject];
        helperObjects.forEach((object) => {
            if (!object) {
                return;
            }
            vt.removeObject(object);
            const indexToRemove = vt.planets.indexOf(object);
            vt.planets.splice(indexToRemove, 1);
        });
    },

    calculateAveragedEarthOrbitPoints() {
        const radians = Math.PI / 180;
        const averagedEarthOrbitPoints = [];
        for (let angle = 0; angle <= 360; angle += SynodicCalculationService.AVERAGED_EARTH_ORBIT_STEP_ANGLE) {
            const x = SynodicCalculationService.AVERAGED_EARTH_ORBIT_RADIUS * Math.cos(angle * radians);
            const y = SynodicCalculationService.AVERAGED_EARTH_ORBIT_RADIUS * Math.sin(angle * radians);
            const z = 0;
            averagedEarthOrbitPoints.push([x, y, z]);
        }
        return averagedEarthOrbitPoints;
    },

    checkIsFocusOnObject(designator) {
        const isFocusOnObject = VisualisationService.getSovtFocusedObject() === designator;
        if (!isFocusOnObject) {
            return;
        }
        const vt = window["vt"];
        vt.getViewer().stopFollowingObject();
        SpacekitService.focusOnSovtObject(designator);
    },

    adjustDetectionPolarObjectPosition() {
        const detectionPolarMergedObject = SpacekitService.getSceneObjectByName("detectionPolarMergedOutside");
        if (detectionPolarMergedObject) {
            return;
        }
        const transformedEarthPosition = SynodicCalculationService.getTransformedEarthObjectPosition();
        if (!transformedEarthPosition) {
            return;
        }
        SynodicCalculationService.setDetectionPolarObjectPosition(transformedEarthPosition);
    },

    adjustDetectionPolarMergedPosition() {
        const detectionPolarMergedObject = SpacekitService.getSceneObjectByName("detectionPolarMergedOutside");
        if (!detectionPolarMergedObject) {
            return;
        }
        const transformedEarthPosition = SynodicCalculationService.getTransformedEarthObjectPosition();
        if (!transformedEarthPosition) {
            return;
        }
        SpacekitService.updateMergedDetectionPolar();
    },

    getTransformedEarthObjectPosition() {
        const vt = window["vt"];
        if (!vt || !vt.planets || !vt.planets.length) {
            return null;
        }
        const transformedEarthObject = UtilsService.findItemInObjectList(
            "_id",
            SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME,
            vt.planets
        );
        if (!transformedEarthObject) {
            return null;
        }
        const currentTime = UtilsService.dateToJulian(VisualisationService.getSimulationTime());
        return transformedEarthObject.getPosition(currentTime);
    },

    setDetectionPolarObjectPosition(transformedEarthPostion) {
        const detectionPolarObject = SpacekitService.getSceneObjectByName("detectionPolar");
        if (!detectionPolarObject) {
            return;
        }
        detectionPolarObject.position.setX(transformedEarthPostion[0] - 1);
    },

    getActiveObject(designator) {
        const spaceObjects = window["vt"].spaceObjects;
        const availableIds = SynodicCalculationService.getAvailableSpaceObjectIds(designator);
        return spaceObjects.find(({ _id }) => availableIds.includes(_id));
    },

    calculateObjectDistances(object) {
        if (!window["vt"] || !window["vt"].planets) {
            return { earth: "-", center: "-" };
        }
        const currentDateJd = UtilsService.dateToJulian(VisualisationService.getSimulationTime());
        const planetsObjects = window["vt"].planets;
        const earthObject = planetsObjects.find(
            ({ _id }) => _id === SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME
        );
        const objectPosition = object.getPosition(currentDateJd);
        const earthPosition = earthObject.getPosition(currentDateJd);
        return {
            earth: VisualisationService.distanceToBody(objectPosition, earthPosition),
            center: VisualisationService.distanceToCenter(objectPosition),
        };
    },

    toggleEarthOnScale(isEarthOnScale) {
        const vt = window["vt"];
        const transformedEarthObject = UtilsService.findItemInObjectList(
            "_id",
            SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME,
            vt.planets
        );
        if (!transformedEarthObject) {
            return;
        }
        const earthScale = transformedEarthObject._object3js.scale;
        earthScale.setScalar(isEarthOnScale ? SynodicCalculationService.EARTH_ON_SCALE_FACTOR : 1);
        SynodicCalculationService.checkIsFocusOnObject(SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME);
        const isDetectionPolarVisible =
            SynodicCalculationService.getSelectedCalculatedObject() &&
            VisualisationService.getSettings().objectsSettings.detectionPolar.value;
        if (isDetectionPolarVisible) {
            SynodicCalculationService.adjustDetectionPolarObjectPosition();
            SynodicCalculationService.adjustDetectionPolarMergedPosition();
        }
        const isElongationConeVisible = VisualisationService.getSettings().objectsSettings.elongationCone.value;
        if (isElongationConeVisible) {
            SynodicCalculationService.adjustElongationConePosition();
        }
    },

    checkIsFocusOnAsteroid(isEarthOnScale) {
        const focusedObject = VisualisationService.getSovtFocusedObject();
        const nonAsteroidFocus = ["sun", SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME];
        const isFocusOnAsteroid = focusedObject && !nonAsteroidFocus.includes(focusedObject);
        if (!isFocusOnAsteroid) {
            return;
        }
        const asteroidZoomFactor = isEarthOnScale ? SynodicCalculationService.ASTEROID_ZOOM_FACTOR : 1;
        SpacekitService.setMinCameraDistance(0.004 * asteroidZoomFactor);
    },

    getAvailableSpaceObjectIds(designator) {
        return [
            `${designator}${SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX}`,
            `${designator}${SynodicCalculationService.TRANSFORMED_SPACE_OBJECT_SUFFIX_PERTURBED}`,
        ];
    },

    getCurrentTimeframeLength() {
        const { startDateJulian, endDateJulian } = SynodicCalculationService.getCurrentJulianTimeframe();
        return endDateJulian - startDateJulian + 1;
    },

    getCurrentJulianTimeframe() {
        const { start: startDateJulian, end: endDateJulian } = TimeFrameService.getTimeFrame().converted;
        return { startDateJulian, endDateJulian };
    },

    getStringTimeframe() {
        const { start, end } = TimeFrameService.getTimeFrame();
        return {
            startDateString: `${start}T00:00Z`,
            endDateString: `${end}T00:00Z`,
        };
    },

    calculateTransfomredEarthPointData(earthObject, dayJulian) {
        const earthPosition = earthObject.getPosition(dayJulian);
        const [, , zEarth] = earthPosition;
        const fixedEarthCenterDistance = +VisualisationService.distanceToCenter(earthPosition, 5);
        return { x: fixedEarthCenterDistance, y: 0, z: zEarth };
    },

    createEphemeridesTable(objectPoints) {
        const objectData = objectPoints.map((pointData) => [
            +pointData.epoch,
            +pointData.xa,
            +pointData.ya,
            +pointData.za,
            0,
            0,
            0,
        ]);
        const objectEphemeridesTable = {
            data: objectData,
            distanceUnits: "au",
            timeUnits: "day",
        };
        return new Spacekit.EphemerisTable(objectEphemeridesTable);
    },

    addTransformedEarthObject(earthEphemeridesTable, fakeEarthEphemeridesTable) {
        const vt = window["vt"];
        const objectsSettings = VisualisationService.getSettings().objectsSettings;
        const isEarthOnScale = objectsSettings.earthOnScale.value;
        const earthScaleFactor = isEarthOnScale ? SynodicCalculationService.EARTH_ON_SCALE_FACTOR : 1;
        const transformedEarthObjectOptions = {
            ...SynodicCalculationService.EARTH_OBJECT_DEFAULT_OPTIONS,
            ephemTable: earthEphemeridesTable,
            labelLayer: 1,
            hideOrbit: true,
        };
        const transformedEarthObject = vt.createSphere(
            SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME,
            transformedEarthObjectOptions
        );
        transformedEarthObject._object3js.scale.setScalar(earthScaleFactor);
        vt.planets.push(transformedEarthObject);
        HUDService.createLabel(
            transformedEarthObject,
            UtilsService.hexColor(objectsSettings.earthName.color),
            objectsSettings.earthNameSize.value
        );
        SynodicCalculationService.createEarthRotationHelpers(fakeEarthEphemeridesTable);
    },

    createEarthRotationHelpers(fakeEarthEphemeridesTable) {
        const vt = window["vt"];
        const fakeMoonObject = vt.createSphere(SynodicCalculationService.EARTH_FAKE_MOON_NAME, {
            ephem: new Spacekit.Ephem(SynodicCalculationService.FAKE_MOON_DEFAULT_OPTIONS.ephemData),
            ...SynodicCalculationService.FAKE_MOON_DEFAULT_OPTIONS.data,
            name: SynodicCalculationService.EARTH_FAKE_MOON_NAME,
        });
        const earthPlaceholder = vt.createObject(SynodicCalculationService.EARTH_PLACEHOLDER_NAME, {
            name: SynodicCalculationService.EARTH_PLACEHOLDER_NAME,
            textureUrl: "/static/textures/particle.png",
            scale: [0.01, 0.01, 0.01],
            theme: {
                color: 0xff0000,
            },
            ephemTable: fakeEarthEphemeridesTable,
        });
        fakeMoonObject.orbitAround(earthPlaceholder);
        const helperObjects = [fakeMoonObject, earthPlaceholder];
        helperObjects.forEach((object) => {
            object.getOrbit().setVisibility(false);
            object._object3js.visible = false;
            vt.planets.push(object);
        });
        const earth = UtilsService.findItemInObjectList(
            "_id",
            SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME,
            vt.planets
        );
        const earthTargetPosition = fakeMoonObject.getPosition(vt.getJd());
        const targetVector = new THREE.Vector3(earthTargetPosition[0], earthTargetPosition[1], earthTargetPosition[2]);
        earth._object3js.lookAt(targetVector);
    },

    getSelectedSynodicObjectParameters() {
        const selectedObject = VisualisationService.getSelectedSynodicObjectName();
        if (!selectedObject) {
            return;
        }
        const apiUrl = SynodicCalculationService.getEphemeridesUrl(selectedObject);
        const loadingObjects = SynodicCalculationService.loadingObjectsEphemerides();
        loadingObjects.push(selectedObject);
        return axios(apiUrl).then((response) => response.data.ephemerides);
    },

    getPerturbedSynodicOrbitParameters(objectName) {
        const isSyntheticObject = SyntheticObjectsService.isSyntheticObject(objectName);
        const apiUrl = SynodicCalculationService.getPerturbedUrl(objectName, isSyntheticObject);
        const loadingObjects = SynodicCalculationService.loadingObjectsEphemerides();
        loadingObjects.push(objectName);
        return axios(apiUrl)
            .then((response) => {
                const data = response.data;
                if (data) {
                    loadingObjects.splice(loadingObjects.indexOf(objectName), 1);
                    return data;
                }
            })
            .catch((error) => {
                loadingObjects.splice(loadingObjects.indexOf(objectName), 1);
                console.log(error);
            });
    },

    getImpactorsList() {
        const apiUrl = `${Config.api.restUrl}${Config.api.impactors}`;
        return axios(apiUrl)
            .then((response) => {
                const formattedData = response.data.map((impactorInfo) => ({
                    designator: SynodicCalculationService.getDesignator(impactorInfo),
                    date: impactorInfo.impactDate,
                }));
                return formattedData;
            })
            .catch((error) => {
                const message = UtilsService.prepareErrorMessage(error);
                PopupService.show({
                    component: "PopupInfo",
                    type: "error",
                    message,
                });
            });
    },

    checkImpactDate(designator) {
        const impactorsList = SynodicCalculationService.getImpactors();
        const impactorObject = UtilsService.findItemInObjectList("designator", designator, impactorsList);
        if (!impactorObject) {
            return null;
        }
        const { start, end } = TimeFrameService.getTimeFrame();
        const startTimestamp = new Date(start).getTime();
        const endTimestamp = new Date(end).getTime();
        const impactTimestamp = new Date(impactorObject.date).getTime();
        const isImpactInSelectedDates = impactTimestamp <= endTimestamp && impactTimestamp >= startTimestamp;
        return isImpactInSelectedDates ? impactorObject.date : null;
    },

    verifyVisiblePerturbedObjects(currentDate) {
        const visibleRealSynodicObjectsList = SynodicCalculationService.getVisibleRealSynodicObjectsList();
        if (!visibleRealSynodicObjectsList.length) {
            return;
        }
        const impactorsList = SynodicCalculationService.getImpactors();
        const perturbedOrbitList = SynodicCalculationService.getPerturbedSynodicOrbitList();
        visibleRealSynodicObjectsList.forEach((objectDesignator) => {
            const isPerturbedOrbit = perturbedOrbitList.includes(objectDesignator);
            if (!isPerturbedOrbit) {
                return;
            }
            const impactorInfo = UtilsService.findItemInObjectList("designator", objectDesignator, impactorsList);
            if (!impactorInfo) {
                return;
            }
            const currentTimestamp = new Date(currentDate).getTime();
            const impactTimestamp = new Date(impactorInfo.date).getTime();
            const isAfterImpact = currentTimestamp > impactTimestamp;
            const object = UtilsService.findItemInObjectList(
                "_id",
                SynodicCalculationService.getTransformedSpaceObjectId(objectDesignator, true),
                window["vt"].spaceObjects
            );
            const isObjectVisible = object._object3js.visible;
            if (isAfterImpact && isObjectVisible) {
                object._object3js.visible = false;
                HUDService.removeLabel(object);
                return;
            }
            if (!isAfterImpact && !isObjectVisible) {
                object._object3js.visible = true;
                HUDService.createLabel(
                    object,
                    SynodicCalculationService.LABEL_COLOR,
                    SynodicCalculationService.LABEL_SIZE
                );
                return;
            }
        });
    },

    verifyCalculatedRealSynodicObjectsList(finalAsteroidsList) {
        SynodicCalculationService.verifyCalculatedObjects(finalAsteroidsList);
        SynodicCalculationService.verifyVisibleObjects(finalAsteroidsList);
        SynodicCalculationService.verifyPerturbedOrbitList(finalAsteroidsList);
    },

    verifyCalculatedObjects(finalAsteroidsList) {
        const calculatedObjects = UtilsService.deepCopy(
            SynodicCalculationService.getCalculatedRealSynodicObjectsList()
        );
        const selectedSynodicObjectName = VisualisationService.getSelectedSynodicObjectName();
        const filteredCalculatedObjects = calculatedObjects.filter((calculatedObject) => {
            const isSyntheticObject = SyntheticObjectsService.isSyntheticObject(calculatedObject.designator);
            return (
                isSyntheticObject ||
                SynodicCalculationService.checkIsObjectOnList(finalAsteroidsList, calculatedObject.designator)
            );
        });
        const isSelectedCalculated = UtilsService.findItemInObjectList(
            "designator",
            selectedSynodicObjectName,
            filteredCalculatedObjects
        );
        !isSelectedCalculated && VisualisationService.selectSynodicObject(selectedSynodicObjectName);
        SynodicCalculationService.setCalculatedRealSynodicObjectsList(filteredCalculatedObjects);
    },

    verifyVisibleObjects(finalAsteroidsList) {
        const filteredVisibleObjectsList = [];
        const visibleObjectsToRemoveList = [];
        const visibleObjectsList = SynodicCalculationService.getVisibleRealSynodicObjectsList();
        visibleObjectsList.forEach((visibleObjectDesignator) => {
            const isSyntheticObject = SyntheticObjectsService.isSyntheticObject(visibleObjectDesignator);
            return isSyntheticObject ||
                SynodicCalculationService.checkIsObjectOnList(finalAsteroidsList, visibleObjectDesignator)
                ? filteredVisibleObjectsList.push(visibleObjectDesignator)
                : visibleObjectsToRemoveList.push(visibleObjectDesignator);
        });
        if (!visibleObjectsToRemoveList.length) {
            return;
        }
        visibleObjectsToRemoveList.forEach((visibleObjectDesignator) => {
            const isPerturbed =
                SynodicCalculationService.getPerturbedSynodicOrbitList().includes(visibleObjectDesignator);
            SynodicCalculationService.removeExistingSpaceObject(visibleObjectDesignator, isPerturbed);
            SynodicChartService.removeSynodicOrbitObjectChartData(visibleObjectDesignator);
        });
        SynodicCalculationService.setVisibleRealSynodicObjectsList(filteredVisibleObjectsList);
    },

    verifyPerturbedOrbitList(finalAsteroidsList) {
        const perturbedOrbitList = SynodicCalculationService.getPerturbedSynodicOrbitList();
        const filteredPerturbedOrbitList = perturbedOrbitList.filter((visibleObjectDesignator) => {
            const isSyntheticObject = SyntheticObjectsService.isSyntheticObject(visibleObjectDesignator);
            return (
                isSyntheticObject ||
                SynodicCalculationService.checkIsObjectOnList(finalAsteroidsList, visibleObjectDesignator)
            );
        });
        SynodicCalculationService.setPerturbedSynodicOrbitList(filteredPerturbedOrbitList);
    },

    checkIsObjectOnList(finalAsteroidsList, objectDesignator) {
        return finalAsteroidsList.some(
            (asteroid) => SynodicCalculationService.getDesignator(asteroid) === objectDesignator
        );
    },

    getSynodicObjectVisibilityInfo(designator) {
        const visibleObjectsList = SynodicCalculationService.getVisibleRealSynodicObjectsList();
        const objectIndexInVisibleList = visibleObjectsList.indexOf(designator);
        const isObjectVisible = objectIndexInVisibleList !== -1;
        const isObjectSelected = designator === VisualisationService.getSelectedSynodicObjectName();
        return { isObjectSelected, isObjectVisible, objectIndexInVisibleList };
    },

    updateVisibleRealSynodicObjectsList(designator) {
        const visibleObjects = SynodicCalculationService.getVisibleRealSynodicObjectsList();
        const isInvisible = !visibleObjects.includes(designator);
        if (isInvisible) {
            visibleObjects.push(designator);
            SynodicCalculationService.setVisibleRealSynodicObjectsList(visibleObjects);
        }
    },

    getSelectedCalculatedObject() {
        const selectedObjectDesignator = VisualisationService.getSelectedSynodicObjectName();
        if (!selectedObjectDesignator) {
            return null;
        }
        const calculatedObjectsList = SynodicCalculationService.getCalculatedRealSynodicObjectsList();
        return UtilsService.findItemInObjectList("designator", selectedObjectDesignator, calculatedObjectsList);
    },

    calculateOrbitPeriod() {
        const activeObject = SynodicCalculationService.getSynodicActiveObject();
        const calculatedObject = SynodicCalculationService.getSelectedCalculatedObject();
        if (
            !activeObject ||
            !calculatedObject ||
            !calculatedObject.objectData ||
            !calculatedObject.objectData.orbitPeriod
        ) {
            return null;
        }
        return calculatedObject.objectData.orbitPeriod / SynodicCalculationService.EARTH_PERIOD;
    },

    calculateSynodicOrbitPeriod() {
        const activeObject = SynodicCalculationService.getSynodicActiveObject();
        const calculatedObject = SynodicCalculationService.getSelectedCalculatedObject();
        const orbitPeriod = this.calculateOrbitPeriod();
        if (!activeObject || !calculatedObject || orbitPeriod === null) {
            return null;
        }
        if (orbitPeriod === 1) {
            return 1;
        }
        return Math.abs(1 / (1 - 1 / orbitPeriod));
    },

    calculateElongationAngle() {
        const activeObject = SynodicCalculationService.getSynodicActiveObject();
        const calculatedObject = SynodicCalculationService.getSelectedCalculatedObject();
        if (!activeObject || !calculatedObject) {
            return null;
        }
        const currentDateJd = UtilsService.dateToJulian(VisualisationService.getSimulationTime());
        const [xObject, yObject] = activeObject.getPosition(currentDateJd);
        if (!window['vt'] || !window['vt'].planets) {
            return null;
        }
        const transformedEarthObject = UtilsService.findItemInObjectList(
            "_id",
            SynodicCalculationService.TRANSFORMED_EARTH_OBJECT_NAME,
            window['vt'].planets
        );
        if (!transformedEarthObject) {
            return null;
        }
        const [xTransformedEarth] = transformedEarthObject.getPosition(currentDateJd);
        const elongationAngle = Math.atan2(yObject, xTransformedEarth - xObject) * 180/Math.PI;
        return isNaN(elongationAngle) ? null : elongationAngle;
    },

    getCurrentChartValue(propertyName) {
        const activeObject = SynodicCalculationService.getSynodicActiveObject();
        const calculatedObject = SynodicCalculationService.getSelectedCalculatedObject();
        if (!activeObject || !calculatedObject) {
            return null;
        }
        const { date, objects } = SynodicChartService.getSynodicOrbitChartsData();
        const isPerturbedOrbit = SynodicCalculationService.getPerturbedSynodicOrbitList().includes(
            calculatedObject.designator
        );
        const objectDesignator = isPerturbedOrbit
            ? `${calculatedObject.designator}_perturbed`
            : calculatedObject.designator;
        if (!objects[objectDesignator]) {
            return null;
        }
        const currentTime = VisualisationService.getSimulationTime();
        const currentTimestamp = moment(UtilsService.dateToStringTechnical(currentTime)).valueOf();
        const dataIndex = date.findIndex((date) => date === currentTimestamp);
        if (dataIndex === -1) {
            return null;
        }
        const chartData = objects[objectDesignator][propertyName];
        if (!chartData[dataIndex]) {
            return null;
        }
        return chartData[dataIndex];
    },

    toogleElongationCone(isVisible) {
        isVisible ? SynodicCalculationService.showElongationCone() : SynodicCalculationService.hideElongationCone();
    },

    showElongationCone() {
        const elongationConeObjects = SynodicCalculationService.getElongationConeObjects();
        if (!elongationConeObjects.length) {
            SynodicCalculationService.createElongationConeObject();
            return;
        }
        elongationConeObjects.forEach((object) => (object.visible = true));
        SynodicCalculationService.adjustElongationConePosition();
    },

    hideElongationCone() {
        const elongationConeObjects = SynodicCalculationService.getElongationConeObjects();
        elongationConeObjects.forEach((object) => (object.visible = false));
    },

    createElongationConeObject() {
        const objectsSettings = VisualisationService.getSettings().objectsSettings;
        const coneProperties = objectsSettings.elongationConeProperties.children;
        const color = SynodicCalculationService.getElongationConeColor();
        const transparency = coneProperties.transparency.value;
        const halfAngle = coneProperties.coneAngle.value;
        const { height, radius } = SynodicCalculationService.calculateElongationConeDimensions(halfAngle);
        const transformedEarthPosition = SynodicCalculationService.getTransformedEarthObjectPosition();
        const xConePosition = transformedEarthPosition[0] - height / 2;
        const isDetectionPolarVisible =
            objectsSettings.detectionPolar.value && SpacekitService.getDetectionPolarObjects().length;
        const coneMaterial = new THREE.MeshLambertMaterial({
            color,
            transparent: true,
            opacity: 1 - transparency,
            depthFunc: isDetectionPolarVisible ? THREE.GreaterDepth : THREE.LessEqualDepth,
            side: THREE.DoubleSide,
        });
        const coneGeometry = new THREE.ConeGeometry(radius, height, 64);
        const coneObject = new THREE.Mesh(coneGeometry, coneMaterial);
        coneObject.rotateZ((-90 * Math.PI) / 180);
        coneObject.position.setX(xConePosition);
        coneObject.renderOrder = 5;
        coneObject.name = "elonagtionCone";
        window["vt"].getScene().add(coneObject);
    },

    changeElongationConeAngle(halfAngle) {
        const elongationConeObjects = SynodicCalculationService.getElongationConeObjects();
        if (!elongationConeObjects.length) {
            return;
        }
        const transformedEarthPosition = SynodicCalculationService.getTransformedEarthObjectPosition();
        const { height, radius } = SynodicCalculationService.calculateElongationConeDimensions(halfAngle);
        const xConePosition = transformedEarthPosition[0] - height / 2;
        elongationConeObjects.forEach((object) => {
            object.geometry.dispose();
            object.geometry = new THREE.ConeGeometry(radius, height, 64);
            object.position.setX(xConePosition);
        });
    },

    changeElongationConeTransparency(transparency) {
        const elongationConeObjects = SynodicCalculationService.getElongationConeObjects();
        if (!elongationConeObjects.length) {
            return;
        }
        elongationConeObjects.forEach((object) => (object.material.opacity = 1 - transparency));
    },

    changeElongationConeColor(color) {
        const elongationConeObjects = SynodicCalculationService.getElongationConeObjects();
        if (!elongationConeObjects.length) {
            return;
        }
        elongationConeObjects.forEach((object) =>
            object.material.color.set(UtilsService.convertHexColorToHexNumber(color))
        );
    },

    calculateElongationConeDimensions(halfAngle) {
        const defaultHeight = 3;
        const radians = Math.PI / 180;
        if (halfAngle <= 75) {
            const radius = Math.tan(halfAngle * radians) * defaultHeight;
            return { height: defaultHeight, radius };
        }
        const hypotenuse = defaultHeight / Math.cos(75 * radians);
        const height = hypotenuse * Math.cos(halfAngle * radians);
        const radius = Math.tan(halfAngle * radians) * height;
        return { height, radius };
    },

    adjustElongationConePosition() {
        const elongationConeObjects = SynodicCalculationService.getElongationConeObjects();
        if (!elongationConeObjects.length) {
            return;
        }
        const transformedEarthPosition = SynodicCalculationService.getTransformedEarthObjectPosition();
        elongationConeObjects.forEach((object) => {
            const height = object.geometry.parameters.height;
            const xConePosition = transformedEarthPosition[0] - height / 2;
            object.position.setX(xConePosition);
        });
    },

    changeElongationConeAppearance(isDetectionPolarVisible = false) {
        const elongationConeObjects = SynodicCalculationService.getElongationConeObjects();
        if (!elongationConeObjects.length) {
            return;
        }
        const isVisible = isDetectionPolarVisible && SpacekitService.getDetectionPolarObjects().length;
        elongationConeObjects.forEach(
            (object) => (object.material.depthFunc = isVisible ? THREE.GreaterDepth : THREE.LessEqualDepth)
        );
    },

    getElongationConeColor() {
        const settings = VisualisationService.getSettingsDefaults();
        const color = settings.objectsSettings.elongationConeProperties.children.color.value;
        return UtilsService.convertHexColorToHexNumber(color);
    },

    getElongationConeObjects() {
        const elongationConeNames = ["elonagtionCone"];
        return elongationConeNames
            .map((name) => SpacekitService.getSceneObjectByName(name))
            .filter((elongationConeObject) => elongationConeObject);
    },

    adjustSovtToolState(focusedObject) {
        SynodicCalculationService.createTransformedEarthObject();
        SynodicCalculationService.recalculateVisibleSynodicOrbits();
        SynodicCalculationService.recalculateTransformedPlanets();
        const isFocusedObject = focusedObject && focusedObject !== "sun";
        isFocusedObject && VisualisationService.setSovtFocusedObject(focusedObject);
    },

    getEphemeridesUrl(objectName) {
        return `${Config.api.restUrl}${Config.api.getEphemerides}keplerian?names=${objectName}`;
    },

    getPerturbedUrl(objectName) {
        const apiParameters = SyntheticObjectsService.getSyntheticObjectSovtApiParameters(objectName);
        return `${Config.api.restUrl}${Config.api.synodic}` + UtilsService.parseParams(apiParameters);
    },

    getDesignator(object) {
        return (object.number ? object.number : object.name).toString();
    },

    getObjectParamtersMap() {
        return {
            epoch: "referenceEpoch",
            q: "perihelionDistance",
            aphelion: "aphelionDistance",
            i: "inclination",
            om: "rightAscension",
            w: "perihelionArgument",
            ma: "meanAnomaly",
            mag: "absoluteMagnitude",
            g: "slopeParameter",
        };
    },

    hasPerturbedPoints(pointsData) {
        return !!Object.values(pointsData).length && !!Object.values(pointsData)[0].asteroid;
    },

    showNoPerturbedInfo(designator) {
        PopupService.show({
            component: "PopupInfo",
            type: "warning",
            message: Keys.t.noPerturbed.replace("{objectName}", designator),
        });
    },

    loadingObjectsEphemerides() {
        return store.getters[CommonGetters.loadingObjectsEphemerides];
    },

    getPerturbedSynodicOrbitList() {
        return store.getters[CommonGetters.perturbedSynodicOrbitList];
    },

    setPerturbedSynodicOrbitList(perturbedSynodicOrbitList) {
        return store.dispatch(CommonActions.setPerturbedSynodicOrbitList, perturbedSynodicOrbitList);
    },

    getCalculatedRealSynodicObjectsList() {
        return store.getters[CommonGetters.calculatedRealSynodicObjectsList];
    },

    setCalculatedRealSynodicObjectsList(calculatedRealSynodicObjectsList) {
        return store.dispatch(CommonActions.setCalculatedRealSynodicObjectsList, calculatedRealSynodicObjectsList);
    },

    getVisibleRealSynodicObjectsList() {
        return store.getters[CommonGetters.visibleRealSynodicObjectsList];
    },

    setVisibleRealSynodicObjectsList(visibleRealSynodicObjectsList) {
        return store.dispatch(CommonActions.setVisibleRealSynodicObjectsList, visibleRealSynodicObjectsList);
    },

    setSynodicActiveObject(activeObject) {
        store.dispatch(CommonActions.setSynodicActiveObject, activeObject);
    },

    getSynodicActiveObject() {
        return store.getters[CommonGetters.synodicActiveObject];
    },

    setDetectionPolarPoints(points) {
        store.dispatch(CommonActions.setDetectionPolarPoints, points);
    },

    getDetectionPolarPoints() {
        return store.getters[CommonGetters.detectionPolarPoints];
    },

    setSynodicTimelineSpeed(speed) {
        store.dispatch(CommonActions.setSynodicSpeed, speed);
    },

    getSynodicTimelineSpeed() {
        return store.getters[CommonGetters.synodicSpeed];
    },

    setImpactors(impactorsList) {
        store.dispatch(CommonActions.setImpactorsList, impactorsList);
    },

    getImpactors() {
        return store.getters[CommonGetters.impactorsList];
    },
};

export default SynodicCalculationService;
